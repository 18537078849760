import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.html',
  //   styleUrls: ['./login.css']
})
export class ChangePasswordComponent //implements OnInit 
{
  constructor(private as: ApiService) { }

  form_data = {
    "mobile_no": "",
    "country": "971",
    "old_password": "",
    "new_password": "",
    "confirm_password": ""
  }
  ispassword1 = true;
  ispassword2 = true;
  ispassword3 = true;

  validation_msg = "";

  changePassword() {
    if (this.isValid()) { }


    // if(this.form_data.mobile_no == ""){

    //   (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText="Please Enter Mobile Number";

    // }else{
    //   (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText="";

    //   ////console.log(JSON.stringify(this.form_data))
    //   this.as.getPostData("https://dpapiuat.clubclass.io/api/client/sendotp", {
    //     "mobile": //"971"+
    //     this.form_data.country+this.form_data.mobile_no,
    //     "type": "forgetpassowrd"
    //   }).map(res => {
    //     ////console.log("get:" + JSON.stringify(res));

    //     if(res.status){
    //       (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd";
    //     }


    //   })
    //     // .catch((error: any) => {
    //     //   throw Observable.throw(error);
    //     // })
    //     .subscribe();

    // }



  }
  isValid() {
      this.validation_msg="";
    if (this.form_data.old_password == "") {
      this.validation_msg="Please enter old password";
      return false;
    } else {
      if (this.form_data.new_password == "") {

      this.validation_msg="Please enter new password";
        return false;
      }
      else {
        if (this.form_data.new_password != this.form_data.confirm_password) {

      this.validation_msg="Passwords do not match";
          return false;
        }
      }
    }
    return true;
  }
  //  setCtrCode(selected){
  //    this.country_code_menu.selected=selected
  //     this.form_data["country"]=selected.value;
  //     ////console.log(JSON.stringify(this.form_data))
  //  }
}
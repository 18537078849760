import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'about-us',
  templateUrl: './about-us.html',
//   styleUrls: ['./login.css']
})
export class AboutUsComponent //implements OnInit 
{

 topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
}
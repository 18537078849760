import { SimpleChanges, EventEmitter } from '@angular/core';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
var ForgotPasswordComponent //implements OnInit 
 = /** @class */ (function () {
    function ForgotPasswordComponent(window, as) {
        this.window = window;
        this.as = as;
        this.visible_vars_emit = new EventEmitter();
        this.form_data = {
            "mobile_no": "",
            "country": "971",
            "email_address": ""
        };
        this.country_code_menu = {
            "selected": {},
            "items": [
                {
                    "img": "../../../assets/icons/uae.png",
                    "value": "971",
                    "length": ""
                }
            ]
        };
    }
    ForgotPasswordComponent.prototype.ngOnChanges = function (changes) {
        // console.log("Chg:",JSON.stringify(changes))
        if (changes["country_list"]["currentValue"] != undefined) {
            this.country_code_menu = changes["country_list"]["currentValue"];
            // console.log("Chg:",JSON.stringify(this.country_code_menu))
            this.country_code_menu["selected"] = this.country_code_menu["items"][0];
            console.log("select:" + JSON.stringify(this.country_code_menu["selected"]));
        }
    };
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        // this.otp_by_email=sessionStorage.getItem("otp_choice_by_mail");
        // console.log("otp_by email:"+this.otp_by_email)
        // this.country_code_menu["items"]=[]
        // this.as.getCountryListData().subscribe((result)=>{
        //   result=result["values"];
        //   result.forEach((element)=>{
        //     this.country_code_menu["items"].push(
        //       {"img":environment.dp_domain+element.flag_image,
        //       "value":element.country_code,
        //       "length":element.mobile_number_length}
        //       )
        //   })
        //   this.country_code_menu["selected"]=this.country_code_menu["items"][0]
        // });
    };
    ForgotPasswordComponent.prototype.sendPassword = function () {
        var _this = this;
        if (this.form_data.mobile_no == "" && this.form_data.email_address == "") {
            document.getElementById("forgot_error_msg").innerText = "Please Enter Mobile Number or Email Address";
        }
        else {
            document.getElementById("forgot_error_msg").innerText = "";
            //console.log(JSON.stringify(this.form_data))
            // let fp_body = new FormData();
            // fp_body.append('country_code', this.form_data.country);
            console.log('mobile', this.form_data.mobile_no);
            console.log('type', 'forgot_password');
            console.log('email', this.form_data.email_address);
            // console.log("fpbody:"+JSON.stringify(fp_body));
            this.as.getPostData(environment.dp_send_otp, 
            // +"mobile="+this.form_data.mobile_no+"&type=forgot_password&country_code="+ this.form_data.country, 
            // fp_body
            {
                "mobile": //"971"+
                this.form_data.mobile_no,
                "email": this.form_data.email_address,
                "type": "forgot_password",
                "country_code": this.form_data.country,
                "verify_by": "email"
            }).map(function (res) {
                //console.log("get:" + JSON.stringify(res));
                if (res.status) {
                    for (var i = 1; i < 7; i++) {
                        document.getElementById("num" + i).value = "";
                    }
                    document.getElementById("from_route").value = "FOG";
                    document.getElementById("from_isd").value = _this.form_data.country;
                    if (_this.form_data.mobile_no == "")
                        _this.form_data.mobile_no = res["values"]["mobile_number"];
                    var mobile_no = (_this.form_data.mobile_no == "") ? res["values"]["mobile_number"] : _this.form_data.mobile_no;
                    document.getElementById("from_mobile").value = mobile_no;
                    document.getElementById("otpverification").className = "forget-pswd";
                    document.getElementById("forgetpassword").className = "forget-pswd collapse";
                }
                else {
                    document.getElementById("forgot_error_msg").innerText = res.message;
                }
            })
                // .catch((error: any) => {
                //   throw Observable.throw(error);
                // })
                .subscribe();
        }
    };
    ForgotPasswordComponent.prototype.back = function () {
        document.getElementById("login-popup").className = "form-popup";
        document.getElementById("forgetpassword").className = "forget-pswd collapse";
        document.getElementById("mobileno").value = "";
    };
    ForgotPasswordComponent.prototype.validateMobileno = function (evt) {
        var theEvent = evt || this.window.event;
        var regex = /[0-9]/;
        if (!regex.test(evt.key) && evt.which != 8 && evt.which != 0) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    };
    ForgotPasswordComponent.prototype.setCtrCode = function (selected) {
        this.country_code_menu.selected = selected;
        this.form_data["country"] = selected.value;
        this.form_data.mobile_no = "";
        //console.log(JSON.stringify(this.form_data))
    };
    ForgotPasswordComponent.prototype.setFirstClearSecond = function (first_id, second_id) {
        this.form_data[second_id] = "";
        // (<HTMLInputElement>document.getElementById(second_id)).value = "";
    };
    ForgotPasswordComponent.prototype.openLogin = function () {
        // setTimeout(()=>{
        this.visible_vars_emit.emit({ "login_visible": true, "signup_visible": false, "forgot_pass_visible": false });
        // },0);
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };

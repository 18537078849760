import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'change-forgot-password',
  templateUrl: './change-password.html',
  //   styleUrls: ['./login.css']
})
export class ChangeForgotPasswordComponent //implements OnInit 
{
  constructor(private as: ApiService) { }

  form_data = {
    "new_password": "",
    "confirm_password": ""
  }
  ispassword1 = true;
  ispassword2 = true;
  ispassword3 = true;

  validation_msg = "";

  changePassword() {
    if (this.isValid()) {
      var mobileno = (<HTMLInputElement>document.getElementById("from_mobile")).value;

      this.as.getPutData(environment.dp_forgat_pssword, {
        "password":this.form_data.confirm_password,
        "mobile": mobileno
      }).map(res => {
        ////console.log("get:" + JSON.stringify(res));
        if(res.status){
          (<HTMLDivElement>document.getElementById("changeforgotpassword")).className = "form-popup collapse";
          (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup";
        }else{
          this.validation_msg= res.message;
        }
      })
        .subscribe();
     }

  
  }
  

  cancel(){
    this.form_data.new_password = "";
    this.form_data.confirm_password = "";
    (<HTMLDivElement>document.getElementById("changeforgotpassword")).className = "form-popup collapse";
    (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup";
  }


  isValid() {
      this.validation_msg="";
      if (this.form_data.new_password == "") {
      this.validation_msg="Please enter new password";
        return false;
      }else if(this.form_data.confirm_password == ""){
        this.validation_msg="Please enter confirm password";
      }
      else {
        if (this.form_data.new_password != this.form_data.confirm_password) {
          this.validation_msg="Passwords do not match";
          return false;
        }
      }
    
    return true;
  }
  //  setCtrCode(selected){
  //    this.country_code_menu.selected=selected
  //     this.form_data["country"]=selected.value;
  //     ////console.log(JSON.stringify(this.form_data))
  //  }
}
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'footer-component',
  templateUrl: './footer.html',
//   styleUrls: ['./login.css']
})
export class FooterComponent //implements OnInit 
{

  scroll(scrillid) {
    if (sessionStorage.getItem("token")) {
      var x = location.href;
      if (x.endsWith("/home")) {
        $('html, body').animate({
          scrollTop: $(scrillid).offset().top
        }, 300);
      } else {
        location.href = "./home"+scrillid;
      }
    } else {
      // $('html, body').animate({
      //   scrollTop: $(scrillid).offset().top
      // }, 300);
      var x = location.href;
      // alert(x)
      if(x.endsWith("/faq")|| x.endsWith("/terms")){
        location.href = "./home"+scrillid;
      }else{
        $('html, body').animate({
          scrollTop: $(scrillid).offset().top
        }, 300);
      }
    
    }
  }

  hideLogin(scrillid) {
    (<HTMLDivElement>document.getElementById("register-popup")).className = "form-popup registerpopup";
    (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup collapse";
    (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";
    (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
    $('html, body').animate({
      scrollTop: $(scrillid).offset().top
    }, 300);
    (<HTMLInputElement>document.getElementById("FirstName")).value = "";
    (<HTMLInputElement>document.getElementById("LastName")).value = "";
    (<HTMLInputElement>document.getElementById("Email")).value = "";
    (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
    (<HTMLInputElement>document.getElementById("signPassword")).value = "";
  }



}
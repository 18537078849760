import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
var CollectComponent //implements OnInit 
 = /** @class */ (function () {
    function CollectComponent(as) {
        this.as = as;
        this.collect_partner_list = [];
        this.functionality = "visit";
        this.backend_url = environment.dp_domain;
    }
    CollectComponent.prototype.ngOnInit = function () {
        var _this = this;
        window.scroll(0, 0);
        this.as.getData("/merchant/get_merchant_list?type=GEOCHECKIN").subscribe(function (result) {
            //  console.log("==========>"+JSON.stringify(result))
            // result=result["values"];
            if (result["status"]) {
                _this.collect_partner_list = result["values"];
                // result.forEach((element)=>{
                //   this.country_code_menu["items"].push(
                //     {"img":environment.dp_domain+element.flag_image,
                //     "value":element.country_code,
                //     "length":element.mobile_number_length,
                //   "name":element.name}
                //     )
                // })
                // console.log("valsu:",this.collect_partner_list)
            }
        });
    };
    CollectComponent.prototype.makeTabActive = function (functionality) {
        var _this = this;
        var param = "", visit_tab = document.getElementById("visit_tab"), click_tab = document.getElementById("click_tab");
        if (functionality == "visit") {
            param = "GEOCHECKIN";
            visit_tab.className = "tab_active";
            click_tab.className = "tab_inactive";
            this.functionality = "visit";
        }
        else if (functionality == "click") {
            param = "WEBCHECKIN";
            visit_tab.className = "tab_inactive";
            click_tab.className = "tab_active";
            this.functionality = "click";
        }
        this.as.getData("/merchant/get_merchant_list?type=" + param).subscribe(function (result) {
            // console.log("==========>"+JSON.stringify(result))
            _this.collect_partner_list = [];
            // result=result["values"];
            if (result["status"]) {
                _this.collect_partner_list = result["values"];
                //  console.log("valsu:",this.collect_partner_list)
            }
        });
    };
    return CollectComponent;
}());
export { CollectComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topbar/login/login-component.ngfactory";
import * as i3 from "./topbar/login/login-component";
import * as i4 from "@ng-toolkit/universal";
import * as i5 from "./api.service";
import * as i6 from "./core/auth.service";
import * as i7 from "./core/countrylist.service";
import * as i8 from "./topbar/forgot-password/forgot-password.ngfactory";
import * as i9 from "./topbar/forgot-password/forgot-password";
import * as i10 from "./topbar/signup/signup-component.ngfactory";
import * as i11 from "./topbar/signup/signup-component";
import * as i12 from "./topbar/otp-verify/otp-verify.ngfactory";
import * as i13 from "./topbar/otp-verify/otp-verify";
import * as i14 from "./topbar/top-bar-component.ngfactory";
import * as i15 from "./topbar/top-bar-component";
import * as i16 from "@angular/common";
import * as i17 from "./topbar/change-password/change-password.ngfactory";
import * as i18 from "./topbar/change-password/change-password";
import * as i19 from "./topbar/change-forgot-password/change-password.ngfactory";
import * as i20 from "./topbar/change-forgot-password/change-password";
import * as i21 from "@angular/router";
import * as i22 from "./footer/footer.ngfactory";
import * as i23 from "./footer/footer";
import * as i24 from "@angular/http";
import * as i25 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "login-component", [], null, [[null, "visible_vars_emit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visible_vars_emit" === en)) {
        var pd_0 = (_co.toggleGlobal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(1, 638976, null, 0, i3.LoginComponent, [i4.WINDOW, i5.ApiService, i6.AuthService, i7.CountryListService], { country_list: [0, "country_list"] }, { visible_vars_emit: "visible_vars_emit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.country_list; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "forgot-password", [], null, [[null, "visible_vars_emit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visible_vars_emit" === en)) {
        var pd_0 = (_co.toggleGlobal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ForgotPasswordComponent_0, i8.RenderType_ForgotPasswordComponent)), i1.ɵdid(1, 638976, null, 0, i9.ForgotPasswordComponent, [i4.WINDOW, i5.ApiService], { country_list: [0, "country_list"] }, { visible_vars_emit: "visible_vars_emit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.country_list; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "signup-component", [], null, [[null, "visible_vars_emit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visible_vars_emit" === en)) {
        var pd_0 = (_co.toggleGlobal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_SignUpComponent_0, i10.RenderType_SignUpComponent)), i1.ɵdid(1, 638976, null, 0, i11.SignUpComponent, [i4.WINDOW, i5.ApiService, i6.AuthService], { country_list: [0, "country_list"] }, { visible_vars_emit: "visible_vars_emit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.country_list; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "otp-verify", [], null, null, null, i12.View_OTPVerifyComponent_0, i12.RenderType_OTPVerifyComponent)), i1.ɵdid(1, 114688, null, 0, i13.OTPVerifyComponent, [i5.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "top-bar-component", [], null, [[null, "visible_vars_emit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visible_vars_emit" === en)) {
        var pd_0 = (_co.toggleGlobal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_TopBarComponent_0, i14.RenderType_TopBarComponent)), i1.ɵprd(4608, null, i7.CountryListService, i7.CountryListService, [i5.ApiService]), i1.ɵdid(2, 638976, null, 0, i15.TopBarComponent, [i1.PLATFORM_ID, i5.ApiService], { country_list: [0, "country_list"], visible_vars: [1, "visible_vars"] }, { visible_vars_emit: "visible_vars_emit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.country_list; var currVal_1 = _co.visible_vars; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(5, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(7, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "change-password", [], null, null, null, i17.View_ChangePasswordComponent_0, i17.RenderType_ChangePasswordComponent)), i1.ɵdid(9, 49152, null, 0, i18.ChangePasswordComponent, [i5.ApiService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "change-forgot-password", [], null, null, null, i19.View_ChangeForgotPasswordComponent_0, i19.RenderType_ChangeForgotPasswordComponent)), i1.ɵdid(11, 49152, null, 0, i20.ChangeForgotPasswordComponent, [i5.ApiService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(13, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, [[null, "name1"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("name1" === en)) {
        var pd_0 = (_co.title !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 212992, null, 0, i21.RouterOutlet, [i21.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "footer-component", [], null, null, null, i22.View_FooterComponent_0, i22.RenderType_FooterComponent)), i1.ɵdid(17, 49152, null, 0, i23.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isbrowser && _co.visible_vars.login_visible); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isbrowser && _co.visible_vars.forgot_pass_visible); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.isbrowser && _co.visible_vars.signup_visible); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isbrowser; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.isbrowser; _ck(_v, 13, 0, currVal_4); _ck(_v, 15, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(512, null, i5.ApiService, i5.ApiService, [i24.Http]), i1.ɵdid(2, 49152, null, 0, i25.AppComponent, [i1.PLATFORM_ID, i5.ApiService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i25.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment'

@Component({
  selector: 'collect-component',
  templateUrl: './collect-component.html',
//   styleUrls: ['./login.css']
})
export class CollectComponent //implements OnInit 
{
  constructor(private as: ApiService) { }
  collect_partner_list=[];
  functionality="visit";
  backend_url=environment.dp_domain;
  ngOnInit(){
    window.scroll(0, 0);
    
    this.as.getData("/merchant/get_merchant_list?type=GEOCHECKIN").subscribe((result)=>{
      
      //  console.log("==========>"+JSON.stringify(result))
        // result=result["values"];
        if(result["status"]){
          this.collect_partner_list=result["values"];
        // result.forEach((element)=>{
        //   this.country_code_menu["items"].push(
        //     {"img":environment.dp_domain+element.flag_image,
        //     "value":element.country_code,
        //     "length":element.mobile_number_length,
        //   "name":element.name}
        //     )
        // })
          // console.log("valsu:",this.collect_partner_list)
        }
      });
  }
  makeTabActive(functionality){
    var param="", visit_tab=<HTMLDivElement>document.getElementById("visit_tab"), 
    click_tab=<HTMLDivElement>document.getElementById("click_tab");
    if(functionality=="visit"){
      param="GEOCHECKIN";
      visit_tab.className="tab_active";
      click_tab.className="tab_inactive";
      this.functionality="visit";
    }
    else if(functionality=="click"){
      param="WEBCHECKIN";
      visit_tab.className="tab_inactive";
      click_tab.className="tab_active";
      this.functionality="click";
    }
    
    this.as.getData("/merchant/get_merchant_list?type="+param).subscribe((result)=>{
      // console.log("==========>"+JSON.stringify(result))
      this.collect_partner_list=[];
       // result=result["values"];
       if(result["status"]){
         this.collect_partner_list=result["values"];
        //  console.log("valsu:",this.collect_partner_list)
       }
     });
  }
}

			<div class="forget-pswd collapse" id="forgetpassword">
				<div class="pswd">
    				<div class="login-header">
    					<h3>Forgot Password</h3>
						<p> An Otp would be sent to your registered mobile number to reset your password </p>
					</div>
    					<div class="login-form" id="forgot_password_form">
    						<div class="mobile border_blue">
								<label class="dropdown" id="countrycodedrp">
    								
    								<div class="selectedcountry" data-toggle="dropdown" aria-expanded="false">
    									<img [src]="country_code_menu['selected']['img']" alt="">+{{country_code_menu['selected']['value']}}
    									<i class="fa fa-angle-down"></i>
    								</div>
   				 					<ul class="dropdown-menu">    
										<span *ngFor="let menu of country_code_menu['items']"	>				
      										<li (click)="setCtrCode(menu)"><a ><img [src]="menu.img" alt="">+{{menu.value}}</a></li> 
										</span>
									</ul>
  								</label>
								  <input type="text" class="border_blue" (keypress)="validateMobileno($event)" (focus)="setFirstClearSecond('mobile_no','email_address')" 
								  placeholder="Enter Mobile Number" id="mobileno" [(ngModel)]="form_data.mobile_no" [maxlength]="country_code_menu['selected']['length']">
							</div>
							
							<b style="padding:0px 50px">OR</b> 
    						<div class="mobile border_blue">
  								<input type="text" class="border_blue"  (focus)="setFirstClearSecond('email_address','mobile_no')" placeholder="Enter Email" id="forgot_email" [(ngModel)]="form_data.email_address">
							</div>							
  							<button class="btn submitbutton" (click)="sendPassword()">SUBMIT</button>
						</div>
						<div  id="forgot_error_msg" class="text text-danger" ></div> 
						<div    style="margin-top: 15px;"><a (click)="openLogin()" data-toggle="collapse" data-target="#login-popup"><i class="fa fa-angle-left" style="margin: 10px;"></i>BACK</a></div>
					  
				</div>
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
var ChangeForgotPasswordComponent //implements OnInit 
 = /** @class */ (function () {
    function ChangeForgotPasswordComponent(as) {
        this.as = as;
        this.form_data = {
            "new_password": "",
            "confirm_password": ""
        };
        this.ispassword1 = true;
        this.ispassword2 = true;
        this.ispassword3 = true;
        this.validation_msg = "";
    }
    ChangeForgotPasswordComponent.prototype.changePassword = function () {
        var _this = this;
        if (this.isValid()) {
            var mobileno = document.getElementById("from_mobile").value;
            this.as.getPutData(environment.dp_forgat_pssword, {
                "password": this.form_data.confirm_password,
                "mobile": mobileno
            }).map(function (res) {
                ////console.log("get:" + JSON.stringify(res));
                if (res.status) {
                    document.getElementById("changeforgotpassword").className = "form-popup collapse";
                    document.getElementById("login-popup").className = "form-popup";
                }
                else {
                    _this.validation_msg = res.message;
                }
            })
                .subscribe();
        }
    };
    ChangeForgotPasswordComponent.prototype.cancel = function () {
        this.form_data.new_password = "";
        this.form_data.confirm_password = "";
        document.getElementById("changeforgotpassword").className = "form-popup collapse";
        document.getElementById("login-popup").className = "form-popup";
    };
    ChangeForgotPasswordComponent.prototype.isValid = function () {
        this.validation_msg = "";
        if (this.form_data.new_password == "") {
            this.validation_msg = "Please enter new password";
            return false;
        }
        else if (this.form_data.confirm_password == "") {
            this.validation_msg = "Please enter confirm password";
        }
        else {
            if (this.form_data.new_password != this.form_data.confirm_password) {
                this.validation_msg = "Passwords do not match";
                return false;
            }
        }
        return true;
    };
    return ChangeForgotPasswordComponent;
}());
export { ChangeForgotPasswordComponent };

<!--<login-component>
</login-component>
<forgot-password>
</forgot-password>
<signup-component>
</signup-component>
<top-bar-component>
</top-bar-component>-->
<carousel-component>
</carousel-component>
<collect-component>
</collect-component>
<redeem-component>
</redeem-component>
<about-us>
</about-us>
<help-form>
</help-form>
<!--<footer-component>
</footer-component>-->
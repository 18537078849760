/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./about-us";
var styles_AboutUsComponent = [];
var RenderType_AboutUsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AboutUsComponent, data: {} });
export { RenderType_AboutUsComponent as RenderType_AboutUsComponent };
export function View_AboutUsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "section", [["id", "aboutus_section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "about_us"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "header-all"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["GET IN TOUCH"])), (_l()(), i0.ɵeld(5, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "aboutus-description"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["If you would like to chat or discuss interest, feel free to reach out to us at: "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email \u2013 support@citypoints.ae "])), (_l()(), i0.ɵeld(11, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please "])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "a", [["routerLink", "/tnc"], ["style", "text-decoration: none;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["click here"])), (_l()(), i0.ɵted(-1, null, [" to view our programs terms and conditions."])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "button", [["id", "myBtn"], ["style", "background-color: black;"], ["title", "Go to top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.topFunction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "img", [["alt", "back to top arrow"], ["src", "../../../assets/images/backtotop-arrow.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/tnc"; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 14).target; var currVal_1 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_0, currVal_1); }); }
export function View_AboutUsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "about-us", [], null, null, null, View_AboutUsComponent_0, RenderType_AboutUsComponent)), i0.ɵdid(1, 49152, null, 0, i3.AboutUsComponent, [], null, null)], null, null); }
var AboutUsComponentNgFactory = i0.ɵccf("about-us", i3.AboutUsComponent, View_AboutUsComponent_Host_0, {}, {}, []);
export { AboutUsComponentNgFactory as AboutUsComponentNgFactory };

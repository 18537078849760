import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.css']
})
export class TncComponent implements OnInit {

  scroll:boolean=false;


  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

  scrolling=(s)=>{
    // let sc = s.target.scrollingElement.scrollTop;
    // console.log("Hello");
    // if(sc >=100){this.scroll=true}
    // else{this.scroll=false}
    this.scrollFunction() 
  }
  

  scrollFunction() {
    if(document.getElementById("myBtn")!=null){
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("myBtn").style.display = "block";
    } else {
        document.getElementById("myBtn").style.display = "none";
    }
    }
}

  topFunction() {
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
    window.scroll(0,0);
 


}
}
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'carousel-component',
  templateUrl: './carousel-component.html',
//   styleUrls: ['./login.css']
})
export class CarouselComponent //implements OnInit 
{

}
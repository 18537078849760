import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit, ViewChild, ElementRef, Input , Inject, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.html',
  //   styleUrls: ['./login.css']
})
export class ForgotPasswordComponent //implements OnInit 
{
  constructor(@Inject(WINDOW) private window: Window, private as: ApiService) { }

  @Input() country_list;
  @Output() visible_vars_emit = new EventEmitter<object>();

  form_data = {
    "mobile_no": "",
    "country":"971",
    "email_address":""
  }

country_code_menu={
  "selected":{},
  "items":[
    {
      "img":"../../../assets/icons/uae.png",
      "value":"971",
      "length":""
    }
  ]
}
otp_by_email;

ngOnChanges(changes:SimpleChanges){
  // console.log("Chg:",JSON.stringify(changes))
  if(changes["country_list"]["currentValue"]!=undefined){
  this.country_code_menu=changes["country_list"]["currentValue"];
  // console.log("Chg:",JSON.stringify(this.country_code_menu))
  this.country_code_menu["selected"]=this.country_code_menu["items"][0]
  console.log("select:"+JSON.stringify(this.country_code_menu["selected"]))
  }
}
ngOnInit(){
  // this.otp_by_email=sessionStorage.getItem("otp_choice_by_mail");
  // console.log("otp_by email:"+this.otp_by_email)
  // this.country_code_menu["items"]=[]
  // this.as.getCountryListData().subscribe((result)=>{
  //   result=result["values"];

  //   result.forEach((element)=>{
  //     this.country_code_menu["items"].push(
  //       {"img":environment.dp_domain+element.flag_image,
  //       "value":element.country_code,
  //       "length":element.mobile_number_length}
  //       )
  //   })
  //   this.country_code_menu["selected"]=this.country_code_menu["items"][0]
  // });
}
  sendPassword() {
    if(this.form_data.mobile_no == "" && this.form_data.email_address==""){

      (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText="Please Enter Mobile Number or Email Address";

    }else{
      (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText="";

      //console.log(JSON.stringify(this.form_data))
      // let fp_body = new FormData();

      // fp_body.append('country_code', this.form_data.country);
      console.log('mobile', this.form_data.mobile_no);
      console.log('type', 'forgot_password');
      console.log('email', this.form_data.email_address);
      // console.log("fpbody:"+JSON.stringify(fp_body));

      this.as.getPostData(environment.dp_send_otp,
      // +"mobile="+this.form_data.mobile_no+"&type=forgot_password&country_code="+ this.form_data.country, 
      // fp_body
      {
        "mobile": //"971"+
        this.form_data.mobile_no,
        "email":this.form_data.email_address,
        "type": "forgot_password",
        "country_code":this.form_data.country,
        "verify_by":"email"
      }
      ).map(res => {
        //console.log("get:" + JSON.stringify(res));
  
        if(res.status){
          for(var i=1; i<7; i++){
            (<HTMLInputElement>document.getElementById("num"+i)).value = "";
          }
          (<HTMLInputElement>document.getElementById("from_route")).value = "FOG"; 
          (<HTMLInputElement>document.getElementById("from_isd")).value = this.form_data.country;
          if(this.form_data.mobile_no=="")this.form_data.mobile_no=res["values"]["mobile_number"];
          
          var mobile_no=(this.form_data.mobile_no=="")?res["values"]["mobile_number"]:this.form_data.mobile_no;
          (<HTMLInputElement>document.getElementById("from_mobile")).value = mobile_no;

          (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd";
          (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";

        }else{
          (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText= res.message;

        }
  
  
      })
        // .catch((error: any) => {
        //   throw Observable.throw(error);
        // })
        .subscribe();

    }

        
    
  }


  back(){
    (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup";
    (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";
    (<HTMLInputElement>document.getElementById("mobileno")).value = "";
  }

  validateMobileno(evt) {
    var theEvent = evt || this.window.event;
    var regex = /[0-9]/;
    if (!regex.test(evt.key) && evt.which != 8 && evt.which != 0) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
 setCtrCode(selected){
   this.country_code_menu.selected=selected
    this.form_data["country"]=selected.value;
    this.form_data.mobile_no="";
    //console.log(JSON.stringify(this.form_data))
 }
 setFirstClearSecond(first_id, second_id){
   this.form_data[second_id]="";
  // (<HTMLInputElement>document.getElementById(second_id)).value = "";

 }
 openLogin(){
   // setTimeout(()=>{
   this.visible_vars_emit.emit({"login_visible":true,"signup_visible":false,"forgot_pass_visible":false});
   // },0);
 }
}
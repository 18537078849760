
		<section id="redeem_section">
			<div class="redeem">
                <div class="header-all whitehd"><h3>PROJECTS</h3></div>
                <div class="container">
				<div class="redeem-description">
					<div class="redeem-left">
						<div class="redeem-img">
                            <div class="logowrapper">
							    <img src="../../../assets/images/bounzlogo.png" alt="redeem giftcard" class="profile-pic">
                            </div>
						</div>
					<!-- <p>REDEEM GIFTCARDS</p> -->
					</div>
				<div class="redeem-right">
					<p class="redeem-data">Our first coalition loyalty program that has been designed for residents and tourists in the UAE, BOUNZ will be a fun, flexible, and convenient app for users. Choithrams, one of the oldest and most loved supermarket chains, is the anchor partner for the program. </p>
				</div>
                </div>
                </div>
			</div>
			<!-- comment start <div class="swiper-partner">
                <div class="container slider-padding-top">
                        <div class="slider-collect-data">
                                <h1 class="collect-size">SOME OF OUR GIFT CARD BRANDS</h1>
                        </div> 
			           <div class="swiper-container collect-slider-padding" id="Partners">
                            
                            <div class="swiper-wrapper">
                               


                                 <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/1.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/2.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/3.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/4.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/5.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/6.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/7.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/8.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>     
                                
                               <div class="swiper-slide tile-animation">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/9.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/10.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/11.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/12.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/13.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/14.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/15.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/16.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/17.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>     
                                
                               <div class="swiper-slide tile-animation">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/18.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/19.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/20.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/21.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/22.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/23.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/24.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/25.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/26.png" alt="image"/>
                                        </div>
                                    </a>
                                </div>     
                                
                               <div class="swiper-slide tile-animation">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/27.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/28.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/29.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/30.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/31.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/32.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/33.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/34.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/35.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>     
                                
                               <div class="swiper-slide tile-animation">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/36.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/37.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/38.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/39.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/40.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> comment end -->
                                <!-- <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/41.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>  -->
                                <!--comment start <div class="swiper-slide tile-animation col-md-3">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/42.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/43.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/44.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div>     
                                
                               <div class="swiper-slide tile-animation">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/partners/45.jpg" alt="image"/>
                                        </div>
                                    </a>
                                </div> 

                                 
                                  
                            </div>
                            <div class="swiper-button-next" id="PartnerSwiperNext"></div>
                            <div class="swiper-button-prev" id="PartnerSwiperPrevious"></div>
                </div>
            </div> comment end-->
            
            	<!-- <div class="morepartner">
            		<button class="btnmore">MORE PARTNERS</button>
            	</div> -->
            <!--comment start </div> comment end -->
		</section>

import { OnInit } from '@angular/core';
var TncComponent = /** @class */ (function () {
    function TncComponent() {
        var _this = this;
        this.scroll = false;
        this.scrolling = function (s) {
            // let sc = s.target.scrollingElement.scrollTop;
            // console.log("Hello");
            // if(sc >=100){this.scroll=true}
            // else{this.scroll=false}
            _this.scrollFunction();
        };
    }
    TncComponent.prototype.ngOnInit = function () {
        window.scroll(0, 0);
    };
    TncComponent.prototype.scrollFunction = function () {
        if (document.getElementById("myBtn") != null) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById("myBtn").style.display = "block";
            }
            else {
                document.getElementById("myBtn").style.display = "none";
            }
        }
    };
    TncComponent.prototype.topFunction = function () {
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
        window.scroll(0, 0);
    };
    return TncComponent;
}());
export { TncComponent };

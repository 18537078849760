import { EventEmitter, SimpleChanges } from '@angular/core';
import { ApiService } from '../../api.service';
import { AuthService } from '../../core/auth.service';
import { environment } from '../../../environments/environment';
var SignUpComponent //implements OnInit 
 = /** @class */ (function () {
    function SignUpComponent(window, apiService, authService) {
        this.window = window;
        this.apiService = apiService;
        this.authService = authService;
        // @Output() is_visible = new EventEmitter<boolean>();
        this.visible_vars_emit = new EventEmitter();
        this.visible = new EventEmitter();
        this.form_data = {
            "first_name": "",
            "last_name": "",
            "email": "",
            "mobile_number": "",
            "password": "",
            "sign_up_channel": "11",
            // "age": "",
            "country": "971",
            // "interest":"",
            "mobile": "",
            "uid": "",
            "provider": ""
        };
        this.country_code_menu = {
            "selected": {},
            "items": [
                {
                    "img": "../../../assets/icons/uae.png",
                    "value": "971",
                    "length": ""
                }
            ]
        };
        this.OTP_data = {
            "mobile": "",
            "type": "register"
        };
    }
    SignUpComponent.prototype.ngOnChanges = function (changes) {
        // console.log("Chg:",JSON.stringify(changes))
        if (changes["country_list"]["currentValue"] != undefined) {
            this.country_code_menu = changes["country_list"]["currentValue"];
            // console.log("Chg:",JSON.stringify(this.country_code_menu))
            this.country_code_menu["selected"] = this.country_code_menu["items"][0];
            // console.log("select:"+JSON.stringify(this.country_code_menu["selected"]))
        }
    };
    SignUpComponent.prototype.ngOnInit = function () {
        // this.country_code_menu["items"]=[]
        // this.apiService.getCountryListData().subscribe((result)=>{
        //   result=result["values"];
        //   result.forEach((element)=>{
        //     this.country_code_menu["items"].push(
        //       {"img":environment.dp_domain+element.flag_image,
        //       "value":element.country_code,
        //       "length":element.mobile_number_length}
        //       )
        //   })
        //   this.country_code_menu["selected"]=this.country_code_menu["items"][0]
        // });
    };
    SignUpComponent.prototype.keyDownFunction = function (event) {
        if (event.keyCode == 13) {
            this.register();
            // rest of your code
        }
    };
    SignUpComponent.prototype.register = function () {
        var _this = this;
        console.log("Form data:" + JSON.stringify(this.form_data));
        //console.log("vm"+this.validateMobileNoSubmit(this.form_data.mobile_number))
        if (this.form_data.first_name == "") {
            document.getElementById("signup_error_msg").innerText = "Please Enter First Name.";
            document.getElementById("FirstName").focus();
            console.log("firstname");
        }
        else if (this.form_data.last_name == "") {
            document.getElementById("signup_error_msg").innerText = "Please Enter Last Name.";
        }
        else if (this.form_data.email == "") {
            document.getElementById("signup_error_msg").innerText = "Please Enter  Email.";
        }
        else if (!this.validateEmail(this.form_data.email)) {
            document.getElementById("signup_error_msg").innerText = "Please Enter Valid Email.";
        }
        else if (this.form_data.mobile_number == "" || !this.validateMobileNoSubmit(this.form_data.mobile_number)) {
            document.getElementById("signup_error_msg").innerText = "Please Enter Valid Mobile Number.";
        }
        else if (this.form_data.password == "" && this.form_data.provider == "") {
            document.getElementById("signup_error_msg").innerText = "Please Enter Password.";
        }
        else if (this.form_data.password != "" && this.form_data.provider == "" && !this.validatePasswordSubmit((this.form_data.password))) {
            document.getElementById("signup_error_msg").innerText = "Password should be minimum 6 characters with uppercase, lowercase and a special character.";
        }
        else if (!document.getElementById("termsCheckBox").checked) {
            document.getElementById("signup_error_msg").innerText = "You should agree to the Terms & Conditions.";
        }
        else if (!document.getElementById("above18CheckBox").checked) {
            document.getElementById("signup_error_msg").innerText = "You must be 18 or above.";
        }
        else {
            document.getElementById("signup_error_msg").innerText = "";
            this.apiService.getPostData(environment.dp_send_otp, {
                "country_code": this.form_data.country,
                "mobile": this.form_data.mobile_number,
                "type": "register",
                "verify_by": (document.getElementById("otp_by_mail").checked) ? "email" : "sms"
            }).map(function (otpres) {
                //console.log("data:==============sendotp"+JSON.stringify(otpres))
                // //console.log("data:========otp======sendotp"+otpres.values.otp)
                if (otpres.status) {
                    sessionStorage.setItem("formdata", JSON.stringify(_this.form_data));
                    // sessionStorage.setItem("otp_choice_by_mail",(<HTMLInputElement>document.getElementById("otp_by_mail")).checked.toString());
                    // sessionStorage.setItem("age",this.form_data.age)
                    // sessionStorage.setItem("country",this.form_data.country)
                    // sessionStorage.setItem("email",this.form_data.email)
                    // sessionStorage.setItem("first_name",this.form_data.first_name)
                    // sessionStorage.setItem("last_name",this.form_data.last_name)
                    // sessionStorage.setItem("password",this.form_data.password)
                    // sessionStorage.setItem("sign_up_channel",this.form_data.sign_up_channel)
                    // sessionStorage.setItem("interest",this.form_data.interest);
                    document.getElementById("from_route").value = "REG";
                    document.getElementById("register-popup").className = "form-popup registerpopup collapse";
                    document.getElementById("otpverification").className = "forget-pswd";
                }
                else {
                    //console.log("data:======fail========sendotp"+JSON.stringify(otpres));
                    document.getElementById("signup_error_msg").innerText = otpres.message;
                }
            })
                .subscribe();
        }
    };
    SignUpComponent.prototype.validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    SignUpComponent.prototype.tryFacebookLogin = function () {
        var _this = this;
        this.authService.doFacebookLogin()
            .then(function (res) {
            // console.log("this.authService.tryFacebookLogin()=>"+JSON.stringify(res))  
            _this.form_data.email = res.user.email;
            var user_name = res.user.displayName;
            user_name = user_name.split(" ");
            _this.form_data.first_name = user_name[0];
            _this.form_data.last_name = user_name[1];
            _this.form_data.mobile = res.user.phoneNumber;
            _this.form_data.uid = res.user.uid;
            _this.form_data.provider = "facebook";
            //    var facebookUID = "";
            //    var jsonreq= {
            //       "provider":"facebook",
            //       "uid":facebookUID
            //       }
            //   this.apiService.getPointsData("https://dpapiuat.clubclass.io/api/client/point/get_customer_point",
            //   jsonreq).map(res => {
            document.getElementById("signPassword").style.display = "none";
            document.getElementById("signup_error_msg").innerHTML =
                "<p class='text-success'>&nbsp; Thank you for signup using Facebook, kindly enter mobile no to complete your registration.</p>";
            //  }).subscribe();
        });
    };
    SignUpComponent.prototype.tryGoogleLogin = function () {
        var _this = this;
        this.authService.doGoogleLogin()
            .then(function (res) {
            //console.log("this.authService.doGoogleLogin()>"+JSON.stringify(res))
            // //console.log("this.authService.doGoogleLogin()>"+gmailUID)
            _this.form_data.email = res.user.email;
            var user_name = res.user.displayName;
            user_name = user_name.split(" ");
            _this.form_data.first_name = user_name[0];
            _this.form_data.last_name = user_name[1];
            _this.form_data.mobile = res.user.phoneNumber;
            _this.form_data.uid = res.user.uid;
            _this.form_data.provider = "google";
            document.getElementById("signPassword").style.display = "none";
            document.getElementById("signup_error_msg").innerHTML =
                "<p class='text-success'>&nbsp; Thank you for signup using Google, kindly enter mobile no to complete your registration.</p>";
            //   this.as.getPostData("https://dpapiuat.clubclass.io/api/client/socialLogin",
            //   jsonreq).map(socialLogin => {
            //     //console.log("==========authService=====>"+JSON.stringify(socialLogin))
            //  }).subscribe();
        });
    };
    SignUpComponent.prototype.setCtrCode = function (selected) {
        this.country_code_menu.selected = selected;
        this.form_data["country"] = selected.value;
        this.form_data.mobile_number = "";
        //console.log(JSON.stringify(this.form_data))
    };
    SignUpComponent.prototype.validateMobileNoSubmit = function (value) {
        var match = value.toString().match(/\d{1,10}/g);
        //console.log(">>>"+match)
        if (match == null)
            return false;
        else
            return true;
    };
    SignUpComponent.prototype.validateMobileno = function (evt) {
        var theEvent = evt || this.window.event;
        var regex = /[0-9]/;
        if (!regex.test(evt.key) && evt.which != 8 && evt.which != 0) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    };
    SignUpComponent.prototype.validatetext = function (evt) {
        var theEvent = evt || this.window.event;
        var regex = /^[a-zA-Z [!@#$%^&*]]*$/;
        if (!regex.test(evt.key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    };
    SignUpComponent.prototype.validatePasswordSubmit = function (value) {
        var upper = value.toString().match(/[A-Z]+/g), lower = value.toString().match(/[a-z]+/g), special_chars = value.toString().match(/[^A-Za-z0-9 ]+/g);
        // console.log(">>>"+value+"::"+upper+","+ (lower==null)+","+( special_chars==null))
        if (upper == null || lower == null || special_chars == null || value.toString().length < 6)
            return false;
        else
            return true;
    };
    SignUpComponent.prototype.closePopup = function () {
        // setTimeout(()=>{
        //  this.is_visible.emit(false);
        this.visible_vars_emit.emit({ "login_visible": false, "signup_visible": false, "forgot_pass_visible": false });
        // },0);
    };
    return SignUpComponent;
}());
export { SignUpComponent };

import { Injectable,EventEmitter } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { ApiService } from '../api.service';
import {BehaviorSubject } from 'rxjs';

@Injectable()
export class CountryListService {
    country_list = null;
    // private messageSource= new BehaviorSubject(this.country_list);
    // currentMessage=this.messageSource.asObservable();
    
onGetData: EventEmitter<Object> = new EventEmitter();

    constructor(private apiService: ApiService) {
        console.log("const country")
        // this.setCountryList();
    }

    setCountryList() {
        // if (this.country_list == null) {
            this.apiService.getCountryListData().subscribe((result) => {
                console.log("api country")
                this.country_list=result["values"];
                console.log("after country:",this.country_list)
                // this.messageSource.next(this.country_list)
                this.onGetData.emit(this.country_list)
            })
        // }
    }
    getCountryList(){
        return this.country_list;
    }
}
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./redeem-component";
import * as i2 from "../api.service";
var styles_RedeemComponent = [];
var RenderType_RedeemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RedeemComponent, data: {} });
export { RenderType_RedeemComponent as RenderType_RedeemComponent };
export function View_RedeemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "section", [["id", "redeem_section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "redeem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "header-all whitehd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["PROJECTS"])), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "redeem-description"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "redeem-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "redeem-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "logowrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", "redeem giftcard"], ["class", "profile-pic"], ["src", "../../../assets/images/bounzlogo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "redeem-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "p", [["class", "redeem-data"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Our first coalition loyalty program that has been designed for residents and tourists in the UAE, BOUNZ will be a fun, flexible, and convenient app for users. Choithrams, one of the oldest and most loved supermarket chains, is the anchor partner for the program. "]))], null, null); }
export function View_RedeemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "redeem-component", [], null, null, null, View_RedeemComponent_0, RenderType_RedeemComponent)), i0.ɵdid(1, 114688, null, 0, i1.RedeemComponent, [i2.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedeemComponentNgFactory = i0.ɵccf("redeem-component", i1.RedeemComponent, View_RedeemComponent_Host_0, {}, {}, []);
export { RedeemComponentNgFactory as RedeemComponentNgFactory };

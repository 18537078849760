// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dp_domain:"https://dpapisit.clubclass.io",
  dp_uat_domain:"https://dpapisit.clubclass.io",
  dp_service_path:"/api/client",
  cc_url_feedback: "https://dpapisit.clubclass.io/api/client/feedback/save",
  dp_member_login: "https://dpapisit.clubclass.io/api/client/login",
  dp_member_point: "https://dpapisit.clubclass.io/api/client/point/get_customer_point",
  dp_mem_social_login: "https://dpapisit.clubclass.io/api/client/socialLogin",
  dp_send_otp: "https://dpapisit.clubclass.io/api/client/sendotp",
  dp_verify_otp: "https://dpapisit.clubclass.io/api/client/verifyotp",
  dp_mem_register: "https://dpapisit.clubclass.io/api/client/register",
  dp_forgat_pssword: "https://dpapisit.clubclass.io/api/client/forget_password",
  dp_get_customer_trn: "https://dpapisit.clubclass.io/api/client/point/get_customer_transaction?offset=0&limit=100",
  dp_get_customer_gift: "https://dpapisit.clubclass.io/api/client/gift_cards/get_customer_gifts_details?gift_cards_id=1&customer_gift_cards_id=100",

  firebase: {
    apiKey: "AIzaSyCfqH5sfdpnPQwsQW3-qtvrDXGdrCApPX8",
    authDomain: "dubaipoints-54909.firebaseapp.com",
    databaseURL: "https://dubaipoints-54909.firebaseio.com",
    projectId: "dubaipoints-54909",
    storageBucket: "",
    messagingSenderId: "35784211634"
  },
  envName: "dev"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit, ViewChild,Output,EventEmitter, ElementRef, Input } from '@angular/core';
import { ApiService } from '../../api.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'otp-verify',
  templateUrl: './otp-verify.html',
  //   styleUrls: ['./login.css']
})
export class OTPVerifyComponent implements OnInit {
  constructor(private apiService: ApiService) { }
  otp_by_email;
  num1;
  num2;
  num3;
  num4;
  num5;
  num6;
  @Output() login_status = new EventEmitter<boolean>();


  ngOnInit() {
    // this.otp_by_email=sessionStorage.getItem("otp_choice_by_mail");
    // console.log("otp_by email:"+this.otp_by_email)
    // console.log("JSON.parse(sessionStorage.getItem"+JSON.stringify(JSON.parse(sessionStorage.getItem("formdata"))));
  }


  onVerifiyOTP(){
    var otproute = (<HTMLInputElement>document.getElementById("from_route")).value;
    console.log("<<<<<<<<<<<<<<<<<<<<<<<"+otproute)
    if(otproute == "REG"){
    this.onVerifiyOTPREG()
    }else{
      this.onVerifiyOTPFOG()
    }

   }



   onVerifiyOTPFOG(){
    var otp = this.num1 + this.num2 + this.num3 + this.num4 + this.num5 + this.num6;
    (<HTMLInputElement>document.getElementById("fnewpass")).value= "";
    (<HTMLInputElement>document.getElementById("cnewpass")).value= "";
    if(otp.length != 6){
      (<HTMLDivElement>document.getElementById("otpverify_error_msg")).innerText="Please Enter OTP.";
    }else{
      
      (<HTMLDivElement>document.getElementById("otpverify_error_msg")).innerText="";
      var isd = (<HTMLInputElement>document.getElementById("from_isd")).value;
      var mobileno = (<HTMLInputElement>document.getElementById("from_mobile")).value;

    //  console.log("============++++>"+mobileno + "akhi"+isd+"")

      var VerifiOTP =
      {
        "country_code" : mobileno,
        "otp": otp,
        "mobile": mobileno
      }
      this.apiService.getPostData(environment.dp_verify_otp, VerifiOTP).map(res => {
        if (res.status) {
          (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
          (<HTMLDivElement>document.getElementById("changeforgotpassword")).className = "form-popup";
        }else{
          (<HTMLDivElement>document.getElementById("otpverify_error_msg")).innerText= res.message;
        }
        }).subscribe();
        // (<HTMLInputElement>document.getElementById("FirstName")).value = "";
        // (<HTMLInputElement>document.getElementById("LastName")).value = "";
        // (<HTMLInputElement>document.getElementById("Email")).value = "";
        // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
        // (<HTMLInputElement>document.getElementById("signPassword")).value = "";
        // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
        // (<HTMLInputElement>document.getElementById("showpswd")).value = "";
        (<HTMLDivElement>document.getElementById("login_error_msg")).innerText="";
   }
  }

  onVerifiyOTPREG() {
    var otp = this.num1 + this.num2 + this.num3 + this.num4 + this.num5 + this.num6;
    if(otp.length != 6){
      (<HTMLDivElement>document.getElementById("otpverify_error_msg")).innerText="Please Enter OTP.";
    }else{
      (<HTMLDivElement>document.getElementById("otpverify_error_msg")).innerText="";

      var password = (<HTMLInputElement>document.getElementById("signPassword")).value;
     var form_data = JSON.parse(sessionStorage.getItem("formdata"));

      form_data.mobile = form_data.mobile_number;
      form_data.country_code =  form_data.country;
      var VerifiOTP =
      {
        "otp": otp,
        "country_code" : form_data.country,
        "mobile": form_data.mobile_number
      }
  //  console.log("https://dpapiuat.clubclass.io/api/client/register"+JSON.stringify(form_data))
      this.apiService.getPostData(environment.dp_verify_otp, VerifiOTP).map(res => {
        if (res.status) {
          this.apiService.getPostData(environment.dp_mem_register, form_data).map(res => {
            if (res.status) {

              console.log("environment.dp_verify_otp"+JSON.stringify(res));

              (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
              // (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup";
              this.login(form_data.country,form_data.mobile_number,form_data.password)
              // sessionStorage.clear();
            } else {
            }
          })
            .subscribe();
        } else {
          (<HTMLDivElement>document.getElementById("otpverify_error_msg")).innerText= res.message;

        }
      }).subscribe();
    }

    
  }



  login(isd,mobile, pass) {
  
   
    var login_details = {
      "country_code" : isd,
      "mobile" : mobile,
      "password": pass
    }
    this.apiService.getPostData(environment.dp_member_login, login_details).map(res => {
      //console.log("data:"+JSON.stringify(res))
      if (res["status"] == true) {
        this.login_status.emit(true);
        (<HTMLElement>document.getElementById("login")).className = "dropdown hide";
        (<HTMLElement>document.getElementById("Signup")).className = "dropdown hide";
        (<HTMLElement>document.getElementById("userfullname")).innerText =
          res["values"][0]["first_name"] + " " + res["values"][0]["last_name"];
        (<HTMLElement>document.getElementById("logoutdrp")).className = "dropdown";
        sessionStorage.setItem("token", res["values"][0]["token"]);
        sessionStorage.setItem("first_name", res["values"][0]["first_name"]);
        sessionStorage.setItem("last_name", res["values"][0]["last_name"]);
        sessionStorage.setItem("mobile_number", res["values"][0]["mobile_number"]);
        sessionStorage.setItem("email", res["values"][0]["email"]);
        (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup collapse";
        (<HTMLDivElement>document.getElementById("joinforfree")).className = "joinforfree collapse";
        (<HTMLDivElement>document.getElementById("joinforfreefooter")).style.visibility = "hidden";
        this.getPoint();
        var userdta = {
          "name" : res["values"][0]["first_name"] + res["values"][0]["last_name"],
          "mobile" :res["values"][0]["mobile_number"],
          "email" :  res["values"][0]["email"]
        }
        this.apiService.sharedCurrentData(userdta);
      }
      else {
        (<HTMLDivElement>document.getElementById("signup_error_msg")).innerText = res.message;
      }
    })

      .subscribe();
}

getPoint() {
  this.apiService.getPointsData(environment.dp_member_point,
    sessionStorage.getItem("token")).map(res => {
      //console.log("data:"+JSON.stringify(res));
      if (res["status"] == true) {
        (<HTMLElement>document.getElementById("menu_pts")).innerText =
          res["values"]["total_points"];
          sessionStorage.setItem("userpoint", res["values"]["total_points"])

        if (res.values.profile_pic != "" && res.values.profile_pic != null) {
          // alert("lsjhl");
          (<HTMLImageElement>document.getElementById("logoutimg")).src =
            "https://dpapiuat.clubclass.io" +
            res["values"]["profile_pic"].toString().substring(1, res["values"]["profile_pic"].toString().length);
          sessionStorage.setItem("userimage", "https://dpapiuat.clubclass.io" +
            res["values"]["profile_pic"].toString().substring(1, res["values"]["profile_pic"].toString().length))
        }else{
          sessionStorage.setItem("userimage", "./../../../assets/icons/login.png")          }
      }
    }).subscribe();
}

  back(){
    var otproute = (<HTMLInputElement>document.getElementById("from_route")).value;
    if(otproute == "REG"){
      (<HTMLDivElement>document.getElementById("register-popup")).className = "form-popup registerpopup";
      (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
      }else{
        (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
        (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd ";
      }
  }

}
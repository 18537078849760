/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./change-password";
import * as i3 from "../../api.service";
var styles_ChangeForgotPasswordComponent = [];
var RenderType_ChangeForgotPasswordComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeForgotPasswordComponent, data: {} });
export { RenderType_ChangeForgotPasswordComponent as RenderType_ChangeForgotPasswordComponent };
export function View_ChangeForgotPasswordComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 45, "div", [["class", "form-popup  collapse"], ["id", "changeforgotpassword"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 32, "div", [["class", "loginpanel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "login-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Forgot Password "])), (_l()(), i0.ɵeld(5, 0, null, null, 25, "div", [["class", "login-form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 24, "div", [["class", "help-form takewidth"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 11, "div", [["class", "fnewpass"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "input", [["class", ""], ["id", "fnewpass"], ["placeholder", "NewPassword"]], [[8, "type", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.form_data.new_password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(11, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(13, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "input", [["class", "spaswd"], ["id", "showpswd2"], ["type", "checkbox"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.ispassword2 = !_co.ispassword2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 2, "p", [["class", "showpswd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "label", [["for", "showpswd2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Show Password "])), (_l()(), i0.ɵeld(19, 0, null, null, 11, "div", [["class", "cnewpass"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 5, "input", [["class", ""], ["id", "cnewpass"], ["placeholder", "ConfirmPassword"]], [[8, "type", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 21)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 21).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 21)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 21)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.form_data.confirm_password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(21, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(23, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(25, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(26, 0, null, null, 4, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 0, "input", [["class", "spaswd"], ["id", "showpswd3"], ["type", "checkbox"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.ispassword3 = !_co.ispassword3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 2, "p", [["class", "showpswd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 1, "label", [["for", "showpswd3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Show Password "])), (_l()(), i0.ɵeld(31, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 1, "div", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(33, null, [" ", " "])), (_l()(), i0.ɵeld(34, 0, null, null, 11, "div", [["class", "changepass"]], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, null, 8, "div", [["class", "download-app"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 3, "a", [["class", "btn transparent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 2, "div", [["class", "download-app-btn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(38, 0, null, null, 1, "button", [["class", "btn changepassbutton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(40, 0, null, null, 3, "a", [["class", "btn transparent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 2, "div", [["class", "download-app-btn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, null, null, 1, "button", [["class", "btn changepassbutton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(44, 0, null, null, 1, "a", [["class", "btnclose"]], null, null, null, null, null)), (_l()(), i0.ɵeld(45, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.form_data.new_password; _ck(_v, 11, 0, currVal_8); var currVal_17 = _co.form_data.confirm_password; _ck(_v, 23, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ispassword2 ? "password" : "text"); var currVal_1 = i0.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 13).ngClassValid; var currVal_6 = i0.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = (_co.ispassword3 ? "password" : "text"); var currVal_10 = i0.ɵnov(_v, 25).ngClassUntouched; var currVal_11 = i0.ɵnov(_v, 25).ngClassTouched; var currVal_12 = i0.ɵnov(_v, 25).ngClassPristine; var currVal_13 = i0.ɵnov(_v, 25).ngClassDirty; var currVal_14 = i0.ɵnov(_v, 25).ngClassValid; var currVal_15 = i0.ɵnov(_v, 25).ngClassInvalid; var currVal_16 = i0.ɵnov(_v, 25).ngClassPending; _ck(_v, 20, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_18 = _co.validation_msg; _ck(_v, 33, 0, currVal_18); }); }
export function View_ChangeForgotPasswordComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "change-forgot-password", [], null, null, null, View_ChangeForgotPasswordComponent_0, RenderType_ChangeForgotPasswordComponent)), i0.ɵdid(1, 49152, null, 0, i2.ChangeForgotPasswordComponent, [i3.ApiService], null, null)], null, null); }
var ChangeForgotPasswordComponentNgFactory = i0.ɵccf("change-forgot-password", i2.ChangeForgotPasswordComponent, View_ChangeForgotPasswordComponent_Host_0, {}, {}, []);
export { ChangeForgotPasswordComponentNgFactory as ChangeForgotPasswordComponentNgFactory };

import { Component, OnInit, ViewChild, ElementRef, Input, PLATFORM_ID, Inject, SimpleChanges,Output,EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { CountryListService } from '../core/countrylist.service'
import { isPlatformBrowser } from '@angular/common';
declare var $: any;

@Component({
  selector: 'top-bar-component',
  templateUrl: './top-bar-component.html',
  providers: [CountryListService]
  //   styleUrls: ['./login.css']
})


export class TopBarComponent //implements OnInit 
{
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private apiService: ApiService) {


    // this.ctr_service.currentMessage.subscribe(list=>{
    //   this.list=list;
    //   if(this.list!=null || this.list!=undefined){
    //   // result = this.list["values"];
    //   this.list.forEach((element) => {
    //     this.country_code_menu["items"].push(
    //       {
    //         "img": environment.dp_domain + element.flag_image,
    //         "value": element.name,
    //         "length": element.mobile_number_length
    //       }
    //     )
    //   })
    // }
    // })

  }
  @Input() country_list;
  @Input() visible_vars ;
  // @Output() is_login_visible_emit = new EventEmitter<boolean>();
  // @Output() is_signup_visible_emit = new EventEmitter<boolean>();
  @Output() visible_vars_emit = new EventEmitter<object>();

  is_login_visible=false;
  is_signup_visible=false;

  list;
  first_name = "";
  last_name = "";
  country_code_menu = {
    "selected": {},
    "items": [
      {
        "img": "../../../assets/icons/uae.png",
        "value": "971",
        "length": ""
      }
    ]
  }
  hideSignUp() {
    // (<HTMLDivElement>document.getElementById("register-popup")).className = "form-popup registerpopup collapse";
    // (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";
    // (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
    // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
    // (<HTMLInputElement>document.getElementById("showpswd")).value = "";
    // (<HTMLDivElement>document.getElementById("login_error_msg")).innerText = "";


  }
  hideLogin() {
    // (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup collapse";
    // (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";
    // (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
    // (<HTMLInputElement>document.getElementById("FirstName")).value = "";
    // (<HTMLInputElement>document.getElementById("LastName")).value = "";
    // (<HTMLInputElement>document.getElementById("Email")).value = "";
    // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
    // (<HTMLInputElement>document.getElementById("signPassword")).value = "";
    // (<HTMLDivElement>document.getElementById("login_error_msg")).innerText = "";
    // (<HTMLDivElement>document.getElementById("signup_error_msg")).innerText = "";
  }




  ngOnChanges(changes:SimpleChanges){
    // console.log("Chg:",JSON.stringify(changes))
    if(changes["country_list"]!=undefined && changes["country_list"]["currentValue"]!=undefined){
    this.country_code_menu=changes["country_list"]["currentValue"];
    // console.log("Chg:",JSON.stringify(this.country_code_menu))
    this.country_code_menu["selected"]=this.country_code_menu["items"][0]
    // console.log("select:"+JSON.stringify(this.country_code_menu["selected"]))
    }
  }

  ngOnInit() {

    // this.country_code_menu["items"] = []
    // this.apiService.getCountryListData().subscribe((result) => {
    //   result = result["values"];
    //   result.forEach((element) => {
    //     this.country_code_menu["items"].push(
    //       {
    //         "img": environment.dp_domain + element.flag_image,
    //         "value": element.name,
    //         "length": element.mobile_number_length
    //       }
    //     )
    //   })
    //   this.country_code_menu["selected"] = this.country_code_menu["items"][0]
    // });
    // if (isPlatformBrowser(this.platformId)) {
      this.first_name = sessionStorage.getItem("first_name");
      // alert(this.first_name)
      this.last_name = sessionStorage.getItem("last_name");
    // }

    if (this.first_name == "" || this.first_name == null) {
      // this.router.navigate(["./home"])
    }
    else {

      (<HTMLElement>document.getElementById("login")).className = "dropdown hide";
      (<HTMLElement>document.getElementById("Signup")).className = "dropdown hide";

      (<HTMLElement>document.getElementById("userfullname")).innerText =
        this.first_name + " " + this.last_name;
      (<HTMLElement>document.getElementById("logoutdrp")).className = "dropdown";
    }
  }
  logout() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("first_name");
    sessionStorage.removeItem("last_name");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("mobile_number");
    localStorage.clear();
    location.reload();
  }


  // scroll(scrillid){
  //     $('html, body').animate({
  //         scrollTop: $(scrillid).offset().top
  //     },300);
  // }




  scroll(scrillid) {

    if (sessionStorage.getItem("token")) {
      var x = location.href;
      if (x.endsWith("/home")) {
        $('html, body').animate({
          scrollTop: $(scrillid).offset().top
        }, 300);
      } else {
        location.href = "./home" + scrillid;
      }
    } else {
      var x = location.href;
      // alert(x)
      if (x.endsWith("/faq") || x.endsWith("/terms")) {
        location.href = "./home" + scrillid;
      } else {
        $('html, body').animate({
          scrollTop: $(scrillid).offset().top
        }, 300);
      }

    }
  }  


  scrollto(scrillid) {
    $('html, body').animate({
      scrollTop: $(scrillid).offset().top
    }, 300);
  }
  
  toggleLogin(){
    // console.log("tbb4login:"+this.visible_vars["login_visible"])
    this.visible_vars["login_visible"]=!this.visible_vars["login_visible"];
    // this.is_login_visible_emit.emit(this.visible_vars["login_visible"]);
    // console.log("tblogin:"+this.visible_vars["login_visible"])
   this.visible_vars_emit.emit({"login_visible":this.visible_vars["login_visible"],"signup_visible":false,"forgot_pass_visible":false});

  }
  toggleSignup(){
    this.visible_vars["signup_visible"]=!this.visible_vars["signup_visible"];
    // this.is_signup_visible_emit.emit(this.visible_vars["signup_visible"]);
    
   this.visible_vars_emit.emit({"login_visible":false,"signup_visible":this.visible_vars["signup_visible"],"forgot_pass_visible":false});

  }
}
import { OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
// import { timingSafeEqual } from 'crypto';
var HelpForm = /** @class */ (function () {
    function HelpForm(as) {
        this.as = as;
        this.session = sessionStorage;
        this.form_data = {
            name: "",
            company_name: "",
            email: "",
            phone: "",
            comment: "",
            pattern: "",
            country_id: "DXB"
        };
        this.cmp_name_disabled = false;
        this.country_code_menu = {
            "selected": {},
            "items": [
            // {
            //   "img":"../../../assets/icons/uae.png",
            //   "value":"971",
            //   "length":"",
            //   "name":"UAE"
            // },
            // {
            //   "img":"../../../assets/icons/uae.png",
            //   "value":"91",
            //   "length":"",
            //   "name":"India"
            // }
            ]
        };
        this.partners_menu = {
            "selected": {
                // "img":"../../../assets/icons/uae.png",
                "value": "Subject"
            },
            "items": [
                // {
                //   // "img":"../../../assets/icons/uae.png",
                //   "value": "Subject"
                // },
                // {
                //   // "img":"../../../assets/icons/uae.png",
                //   "value": "Customer"
                // },
                {
                    // "img":"../../../assets/images/ind.jpg",
                    "value": "Become a Partner"
                },
                {
                    // "img":"../../../assets/images/ind.jpg",
                    "value": "General Enquiry"
                }
            ]
        };
        this.country_code_text = "";
        this.country_code_focus = false;
        this.country_code_array = [];
        // onChildEvent(boolean){
        //   alert("Event now!");
        // }
        this.form_error = "All fields are Mandatory";
    }
    HelpForm.prototype.ngOnChanges = function (changes) {
        console.log(changes);
    };
    HelpForm.prototype.ngOnInit = function () {
        var _this = this;
        // console.log(sessionStorage);
        this.form_data.pattern = "Customer";
        if (sessionStorage.getItem("token")) {
            this.form_data.phone = this.session.getItem("mobile_number");
            this.form_data.name = this.session.getItem("first_name") + this.session.getItem("last_name");
            this.form_data.email = this.session.getItem("email");
            if (this.form_data.phone.startsWith('91')) {
                this.form_data.phone = this.form_data.phone.substring(2);
            }
            else {
                this.form_data.phone = this.form_data.phone.substring(3);
            }
        }
        this.country_code_menu["items"] = [];
        this.as.getCountryListData().subscribe(function (result) {
            //  console.log("==========>"+JSON.stringify(result))
            result = result["values"]["country_list"];
            result.forEach(function (element) {
                _this.country_code_menu["items"].push({ "img": environment.dp_domain + element.flag_image,
                    "value": element.country_code,
                    "length": element.mobile_number_length,
                    "name": element.name });
            });
            _this.filterCountryList();
            // this.country_code_menu["selected"]=this.country_code_menu["items"][0]
        });
        //  alert("==========>"+JSON.stringify(this.form_data))
        // alert("hello")
        // if(sessionStorage.getItem("email")){
        //   this.form_data.name=sessionStorage.getItem("first_name") + " "+ sessionStorage.getItem("last_name");
        //   this.form_data.email = sessionStorage.getItem("email");
        //   var mobileno = sessionStorage.getItem("mobile_number");
        //   if(mobileno.startsWith("91")){
        //     this.form_data.phone = mobileno.substring(2);
        //      this.setCtrCode({
        //       "img":"../../../assets/images/ind.jpg",
        //       "value":"91"
        //     })
        //   }else{
        //     this.form_data.phone = mobileno.substring(3);
        //   }
        // }
        // alert(this.first_name)
    };
    HelpForm.prototype.submitHelp = function () {
        var _this = this;
        this.as.currentSharedData.subscribe(function (result) {
            // console.log("====== this.as.currentSharedData.subscribe============>",result['name']);
            if (sessionStorage.getItem("token")) {
                _this.form_data.phone = _this.session.getItem("mobile_number");
                _this.form_data.name = _this.session.getItem("first_name") + " " + _this.session.getItem("last_name");
                _this.form_data.email = _this.session.getItem("email");
                if (_this.form_data.phone.startsWith('91')) {
                    _this.form_data.phone = _this.form_data.phone.substring(2);
                }
                else {
                    _this.form_data.phone = _this.form_data.phone.substring(3);
                }
            }
        });
        var err = false;
        console.log("--------help------>", this.form_data, err);
        if (!err && !this.form_data.email || !this.validateEmail(this.form_data.email)) {
            err = true;
            this.form_error = "Please Enter Valid Email";
            console.log("this.form_data.email ==> ", this.form_data.email);
        }
        if (!this.form_data.name) {
            err = true;
            this.form_error = "Please Enter Valid Name";
            console.log("this.form_data.name ==> ", this.form_data.name);
        }
        if (!err && !this.form_data.phone) {
            err = true;
            this.form_error = "Please Enter Valid Mobile Number";
            console.log("this.form_data.mobile_no ==> ", this.form_data.phone);
        }
        if (!err && !this.form_data.company_name && this.partners_menu.selected.value != 'Customer') {
            err = true;
            this.form_error = "Please Enter Valid Company Name";
            console.log("this.form_data.company_name ==> ", this.form_data.company_name);
        }
        if (!err && !this.form_data.comment) {
            err = true;
            this.form_error = "Please Enter Valid Comment";
            console.log("this.form_data.comment ==> ", this.form_data.comment);
        }
        if (!err) {
            this.form_error = "";
            console.log("environment.cc_url_feedback === ", this.form_data);
            this.as.savehelpData(environment.cc_url_feedback, this.form_data)
                .subscribe(function (resp) {
                console.log(resp);
                if (resp.status) {
                    _this.form_error = //resp.message;
                        "Message sent successfully. Thank you for contacting us.";
                    _this.form_data.comment = "";
                    _this.form_data.company_name = "";
                    _this.form_data.email = "";
                    _this.form_data.name = "";
                    _this.form_data.phone = "";
                }
                else {
                    _this.form_error = resp.message;
                }
            });
        }
    };
    HelpForm.prototype.validateMobileno = function (evt) {
        var theEvent = evt || window.event;
        // // Handle paste
        // if (theEvent.type === 'paste') {
        //     key = event.clipboardData.getData('text/plain');
        // } else {
        // // Handle key press
        //     var key = theEvent.keyCode || theEvent.which;
        //     key = String.fromCharCode(key);
        // }
        var regex = /[0-9]/;
        if (!regex.test(evt.key) && evt.which != 8 && evt.which != 0) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    };
    HelpForm.prototype.validatetext = function (evt) {
        var theEvent = evt || window.event;
        var regex = /^[a-zA-Z ]*$/;
        if (!regex.test(evt.key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    };
    HelpForm.prototype.validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    HelpForm.prototype.setCtrCode = function (selected) {
        this.country_code_focus = false;
        // console.log("fccus:"+this.country_code_focus);
        this.country_code_menu.selected = selected;
        this.country_code_text = this.country_code_menu.selected["value"];
        this.filterCountryList();
        // this.form_data["country"]=selected.value;
        // console.log(JSON.stringify(this.form_data))
    };
    HelpForm.prototype.setPrtnrCode = function (selected) {
        this.partners_menu.selected = selected;
        this.form_data.pattern = this.partners_menu.selected.value;
        //  alert("===========>"+ JSON.stringify(this.form_data.pattern))
        if (this.partners_menu.selected.value == 'Customer')
            this.cmp_name_disabled = true;
        else
            this.cmp_name_disabled = false;
    };
    HelpForm.prototype.filterCountryList = function () {
        var _this = this;
        this.country_code_array = [];
        if (this.country_code_text == "") {
            this.country_code_array = this.country_code_menu["items"];
        }
        else {
            this.country_code_array = this.country_code_menu["items"].filter(function (element) {
                return (element.value.indexOf(_this.country_code_text) == 0 ||
                    element.name.toString().toLowerCase().indexOf(_this.country_code_text.toLowerCase()) == 0);
            });
        }
    };
    HelpForm.prototype.stopPropagation = function () {
        if (event.stopPropagation)
            event.stopPropagation();
    };
    return HelpForm;
}());
export { HelpForm };

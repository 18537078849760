<section id="collect_section">
    <div class="Collect">
        <div class="container  ">
            <div class="header-all">
                <h3>WHAT WE DO</h3>
            </div>

            <div class="aboutus-description">
                <p>City Points is a holding company that specializes in using intuitive technology to create effective loyalty programs - coalition, proprietary or hybrid. A Dubai-based venture, we leverage the city’s potential for innovation to build exciting products, regionally and globally.    </p>
        </div>
            <!-- <div class="Collect-description">

                <div class="description">
                    <div class="description-content">
                        <div id="visit_tab" class="">
                            <div class="image border_red">
                                <img src="../../../assets/icons/visit.png" />
                            </div>
                            <p class="description-header">VISIT</p>

                        </div>
                        
                        <p class="description-long">VISIT any of our partner's locations, check in from our app and
                            collect City Points. It’s that easy.</p>
                    </div>
                </div>
                <div class="description">
                    <div class="description-content">

                        <div id="click_tab" class="">
                            <div class="image border_green">
                                <img src="../../../assets/icons/click.png" />
                            </div>
                            <p class="description-header">CLICK</p>

                        </div>

                        
                        <p class="description-long">CLICK to visit our partner's websites, check in from our app and
                            collect City Points. It’s that easy.</p>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="swiper-partner">
            <div class="container slider-padding-top ">
                <!-- <div class="slider-collect-data">
                    <h1 class="header-all">WHO WE ARE</h1>   
               </div> -->
               <!--class collect-size -->
               <div class="header-all">
                <h3>WHO WE ARE</h3>
            </div>

                <div class="aboutus-description">
                    <p>We understand a client’s need to hold customer attention for the long haul. Since incorporation in 2017, City Points has graduated from the Dubai Future Accelerators Cohort 1 & 2 and won GITEX’s Future Star Competition.  </p>
            </div>

                <div class="swiper-container Collect-description collect-slider-padding" id="partners-site">


                    <div class="swiper-wrapper">




                        <div class="swiper-slide tile-animation col-md-3 collect-right ">
                            <a href="javascript:void(0);" class="swiper-content">
                                <div class="linkedin"><a href="https://www.linkedin.com/in/mark-mortimer-davies-81127a4/" target="#"> <img src="../../assets/images/linkedin.png" alt=""> </a></div>

                                <div class="image">
                                    <img src="../../assets/images/Mark picture.jpg" width="150" alt="image" />
                                </div>

                                <div class="collect-icons">
                                    <!-- <span class="border_red"><img src="../../../assets/icons/visit.png"
                                            alt="image" /></span>
                                    <span class="border_green"><img src="../../../assets/icons/click.png"
                                            alt="image" /></span> -->
                                            Mark Mortimer-Davies
                                </div>
                                <div class="memdescription">
                                    A seasoned leader with three decades of experience in loyalty and retail & FMCG sectors.
                                </div>
                            </a>
                        </div>


                        <div class="swiper-slide tile-animation col-md-3 ">
                            <a href="javascript:void(0);" class="swiper-content">
                                <div class="linkedin"><a href="https://www.linkedin.com/in/sridhar-krishnamurthy-48a10515/" target="#"> <img src="../../assets/images/linkedin.png" alt=""> </a></div>

                                <div class="image">
                                    <img src="../../assets/images/SK 1.jpg" width="150" alt="image" />
                                </div>

                                <div class="collect-icons">
                                    <!-- <span class="border_red"><img src="../../../assets/icons/visit.png"
                                            alt="image" /></span>
                                    <span class="border_green"><img src="../../../assets/icons/click.png"
                                            alt="image" /></span> -->
                                            Sridhar Krishnamurthy 
                                </div>
                                <div class="memdescription">
                                    A travel, retail & loyalty management expert with 25 years of commercial development experience.
                                </div>
                            </a>
                        </div>
                        <div class="swiper-slide tile-animation col-md-3 ">
                            <a href="javascript:void(0);" class="swiper-content">
                                <div class="linkedin"><a href="https://www.linkedin.com/in/tarek-damerji-28295424/" target="#"> <img src="../../assets/images/linkedin.png" alt=""> </a></div>

                                <div class="image">
                                    <img src="../../assets/images/Tarek Damerji-min.jpg" width="150" alt="image" />

                                </div>

                                <div class="collect-icons">

                                    <!-- <span class="border_green"><img src="../../../assets/icons/click.png"
                                            alt="image" /></span> -->
                                            Tarek Damerji 

                                </div>
                                <div class="memdescription">
                                    An ex-banker and co-founder of bayt.com, Tarek is a board member for several contracting companies.
                                </div>
                            </a>
                        </div>
                        <div class="swiper-slide tile-animation col-md-3 ">
                            <a href="javascript:void(0);" class="swiper-content">
                                <div class="linkedin"><a href="https://www.linkedin.com/in/pankaj-t-9261899/" target="#"> <img src="../../assets/images/linkedin.png" alt=""> </a></div>

                                <div class="image">
                                    <img src="../../assets/images/vernost_001-5 (1)-min (1).jpg" width="150" alt="image" />

                                </div>

                                <div class="collect-icons">

                                    <!-- <span class="border_red"><img src="../../../assets/icons/visit.png"
                                            alt="image" /></span>
                                    <span class="border_green"><img src="../../../assets/icons/click.png"
                                            alt="image" /></span> -->
                                            Pankaj Tripathi

                                </div>
                                <div class="memdescription">
                                    An entrepreneur and tech-professional who has designed customer experience solutions.
                                </div>
                            </a>
                        </div>
                        
                        <!-- <div class="swiper-slide tile-animation col-md-3 ">
                            <a href="javascript:void(0);" class="swiper-content">
                                <div class="image">
                                    <img src="../../../assets/images/partners/logo3.png" width="150" alt="image" />

                                </div>

                                <div class="collect-icons">

                                    <span class="border_red"><img src="../../../assets/icons/visit.png"
                                            alt="image" /></span>
                                    <span class="border_green"><img src="../../../assets/icons/click.png"
                                            alt="image" /></span>
                                </div>
                            </a>
                        </div> -->


                        <!-- <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/landmark.png" alt="image"/>
                                        </div>                                        
                                       <hr>
                                        <div class="collect-icons">                                            
											<span class="border_red"><img src="../../../assets/icons/visit.png" alt="image"/></span>
											<span class="border_green"><img src="../../../assets/icons/click.png" alt="image"/></span>
                                        </div>
                                    </a>
                                </div>             -->


                        <!-- <div class="swiper-slide tile-animation col-md-3 ">
                                    <a href="javascript:void(0);" class="swiper-content">
                                        <div class="image">
                                            <img src="../../../assets/images/dubaipark.png" alt="image"/>
                                        </div>                                        
                                       <hr>
                                        <div class="collect-icons">                                            
											<span class="border_red"><img src="../../../assets/icons/visit.png" alt="image"/></span>
											<span class="border_green"><img src="../../../assets/icons/click.png" alt="image"/></span>
                                        </div>
                                    </a>
                                </div>             -->
                    </div>
                    <div class="swiper-button-next mobonext" id="PartnerSiteSwiperNext"></div>
                    <div class="swiper-button-prev mobonext" id="PartnerSiteSwiperPrevious"></div> 

                </div>
            </div>
            <!-- <div class="morepartner">
            		<button class="btnmore">MORE REWARDS</button>
            	</div> -->
        </div>

    </div>
</section>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./carousel-component.ngfactory";
import * as i2 from "./carousel-component";
import * as i3 from "./collect-component.ngfactory";
import * as i4 from "./collect-component";
import * as i5 from "../api.service";
import * as i6 from "./redeem-component.ngfactory";
import * as i7 from "./redeem-component";
import * as i8 from "../about-us/about-us.ngfactory";
import * as i9 from "../about-us/about-us";
import * as i10 from "../help/help-form.ngfactory";
import * as i11 from "../help/help-form";
import * as i12 from "./home-main";
var styles_HomeMain = [];
var RenderType_HomeMain = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeMain, data: {} });
export { RenderType_HomeMain as RenderType_HomeMain };
export function View_HomeMain_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "carousel-component", [], null, null, null, i1.View_CarouselComponent_0, i1.RenderType_CarouselComponent)), i0.ɵdid(1, 49152, null, 0, i2.CarouselComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "collect-component", [], null, null, null, i3.View_CollectComponent_0, i3.RenderType_CollectComponent)), i0.ɵdid(3, 114688, null, 0, i4.CollectComponent, [i5.ApiService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "redeem-component", [], null, null, null, i6.View_RedeemComponent_0, i6.RenderType_RedeemComponent)), i0.ɵdid(5, 114688, null, 0, i7.RedeemComponent, [i5.ApiService], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "about-us", [], null, null, null, i8.View_AboutUsComponent_0, i8.RenderType_AboutUsComponent)), i0.ɵdid(7, 49152, null, 0, i9.AboutUsComponent, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "help-form", [], null, null, null, i10.View_HelpForm_0, i10.RenderType_HelpForm)), i0.ɵdid(9, 638976, null, 0, i11.HelpForm, [i5.ApiService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 9, 0); }, null); }
export function View_HomeMain_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "home-main", [], null, null, null, View_HomeMain_0, RenderType_HomeMain)), i0.ɵdid(1, 114688, null, 0, i12.HomeMain, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeMainNgFactory = i0.ɵccf("home-main", i12.HomeMain, View_HomeMain_Host_0, { name1: "name1", country_list: "country_list" }, {}, []);
export { HomeMainNgFactory as HomeMainNgFactory };

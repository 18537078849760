import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeMain } from './home-page/home-main'
import { TncComponent } from './tnc/tnc.component';
// import { LoginComponent } from './topbar/login/login-component';
// import { ForgotPasswordComponent } from './topbar/forgot-password/forgot-password';
// import { SignUpComponent } from './topbar/signup/signup-component';
// import { TopBarComponent } from './topbar/top-bar-component';
// import { CollectComponent } from './home-page/collect-component';
// import { RedeemComponent } from './home-page/redeem-component';
// import { CarouselComponent } from './home-page/carousel-component';
// import { HelpForm } from './help/help-form';
// import { FooterComponent } from './footer/footer';
// import { FAQComponent } from "./faq/faq";
// import { AccountDetails } from './account-details/account-details';
  // import { TncComponent } from './tnc/tnc.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   data: {
  //     title: 'Home'
  //   }
  // },
  {
    path: 'home',
    component: HomeMain,
    data: {
      title: 'Home',
    },

    //  {
    //    path: 'tnc',
    //    component: TncComponent,
    //    data: {
    //      title: 'tnc',
    //    },
    // children: [
    //   {
    //     path: '',
    //     redirectTo: 'dashboard',
    //     pathMatch: 'full',
    //     data: {
    //       title: 'Home'
    //     }
    //   }
    // ]
  },
  {
    path: 'my-account',
    loadChildren:'./account-details/account-details.module#AccountModule',
    data: {
      title: 'Home'
    }
  },
  {
    path: 'faq',
    loadChildren:'./faq/faq.module#FAQModule'
  },
  {
    path: 'terms',
    loadChildren:'./terms/terms.module#TermsModule'
  },
  {
    path: 'tnc',
    component: TncComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
 }

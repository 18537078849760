import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
var RedeemComponent //implements OnInit 
 = /** @class */ (function () {
    function RedeemComponent(as) {
        this.as = as;
        this.gift_card_list = [];
        this.backend_url = environment.dp_domain;
    }
    RedeemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.as.getData("/gift_cards/get_gift_cards_list?country_id=2").subscribe(function (result) {
            //  console.log("reddddddd==========>"+JSON.stringify(result))
            // result=result["values"];
            if (result["status"]) {
                _this.gift_card_list = result["values"];
                // result.forEach((element)=>{
                //   this.country_code_menu["items"].push(
                //     {"img":environment.dp_domain+element.flag_image,
                //     "value":element.country_code,
                //     "length":element.mobile_number_length,
                //   "name":element.name}
                //     )
                // })
                // console.log("valsu:",this.gift_card_list)
            }
        });
    };
    return RedeemComponent;
}());
export { RedeemComponent };

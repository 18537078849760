<!--The content below is only a placeholder and can be replaced.-->
<!-- <div style="text-align:center">
  <h1>
    Welcome to {{ title }}!
  </h1>
  <img width="300" alt="Angular Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==">
</div>
<h2>Here are some links to help you start: </h2>
<ul>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://angular.io/tutorial">Tour of Heroes</a></h2>
  </li>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://github.com/angular/angular-cli/wiki">CLI Documentation</a></h2>
  </li>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://blog.angular.io/">Angular blog</a></h2>
  </li>
</ul> -->
<!--<router-outlet></router-outlet>-->
<!--  (is_visible)="toggleLogin($event)" -->
<login-component  *ngIf="isbrowser && visible_vars.login_visible" [country_list]="country_list" (visible_vars_emit)="toggleGlobal($event)">
</login-component >
<forgot-password *ngIf="isbrowser && visible_vars.forgot_pass_visible" [country_list]="country_list" (visible_vars_emit)="toggleGlobal($event)">
</forgot-password>
<signup-component *ngIf="isbrowser && visible_vars.signup_visible" [country_list]="country_list" (visible_vars_emit)="toggleGlobal($event)">
</signup-component>
<otp-verify *ngIf="isbrowser">
</otp-verify>
<change-password>
</change-password>
<change-forgot-password></change-forgot-password>

<top-bar-component *ngIf="isbrowser" [country_list]="country_list"
 [visible_vars]="visible_vars" (visible_vars_emit)="toggleGlobal($event)">
</top-bar-component>

<router-outlet (name1)='title'></router-outlet>
<!--<home-main></home-main>-->

<footer-component>
</footer-component>
import { SimpleChanges, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';
var TopBarComponent //implements OnInit 
 = /** @class */ (function () {
    function TopBarComponent(platformId, apiService) {
        // this.ctr_service.currentMessage.subscribe(list=>{
        //   this.list=list;
        //   if(this.list!=null || this.list!=undefined){
        //   // result = this.list["values"];
        //   this.list.forEach((element) => {
        //     this.country_code_menu["items"].push(
        //       {
        //         "img": environment.dp_domain + element.flag_image,
        //         "value": element.name,
        //         "length": element.mobile_number_length
        //       }
        //     )
        //   })
        // }
        // })
        this.platformId = platformId;
        this.apiService = apiService;
        // @Output() is_login_visible_emit = new EventEmitter<boolean>();
        // @Output() is_signup_visible_emit = new EventEmitter<boolean>();
        this.visible_vars_emit = new EventEmitter();
        this.is_login_visible = false;
        this.is_signup_visible = false;
        this.first_name = "";
        this.last_name = "";
        this.country_code_menu = {
            "selected": {},
            "items": [
                {
                    "img": "../../../assets/icons/uae.png",
                    "value": "971",
                    "length": ""
                }
            ]
        };
    }
    TopBarComponent.prototype.hideSignUp = function () {
        // (<HTMLDivElement>document.getElementById("register-popup")).className = "form-popup registerpopup collapse";
        // (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";
        // (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
        // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
        // (<HTMLInputElement>document.getElementById("showpswd")).value = "";
        // (<HTMLDivElement>document.getElementById("login_error_msg")).innerText = "";
    };
    TopBarComponent.prototype.hideLogin = function () {
        // (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup collapse";
        // (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd collapse";
        // (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd collapse";
        // (<HTMLInputElement>document.getElementById("FirstName")).value = "";
        // (<HTMLInputElement>document.getElementById("LastName")).value = "";
        // (<HTMLInputElement>document.getElementById("Email")).value = "";
        // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
        // (<HTMLInputElement>document.getElementById("signPassword")).value = "";
        // (<HTMLDivElement>document.getElementById("login_error_msg")).innerText = "";
        // (<HTMLDivElement>document.getElementById("signup_error_msg")).innerText = "";
    };
    TopBarComponent.prototype.ngOnChanges = function (changes) {
        // console.log("Chg:",JSON.stringify(changes))
        if (changes["country_list"] != undefined && changes["country_list"]["currentValue"] != undefined) {
            this.country_code_menu = changes["country_list"]["currentValue"];
            // console.log("Chg:",JSON.stringify(this.country_code_menu))
            this.country_code_menu["selected"] = this.country_code_menu["items"][0];
            // console.log("select:"+JSON.stringify(this.country_code_menu["selected"]))
        }
    };
    TopBarComponent.prototype.ngOnInit = function () {
        // this.country_code_menu["items"] = []
        // this.apiService.getCountryListData().subscribe((result) => {
        //   result = result["values"];
        //   result.forEach((element) => {
        //     this.country_code_menu["items"].push(
        //       {
        //         "img": environment.dp_domain + element.flag_image,
        //         "value": element.name,
        //         "length": element.mobile_number_length
        //       }
        //     )
        //   })
        //   this.country_code_menu["selected"] = this.country_code_menu["items"][0]
        // });
        // if (isPlatformBrowser(this.platformId)) {
        this.first_name = sessionStorage.getItem("first_name");
        // alert(this.first_name)
        this.last_name = sessionStorage.getItem("last_name");
        // }
        if (this.first_name == "" || this.first_name == null) {
            // this.router.navigate(["./home"])
        }
        else {
            document.getElementById("login").className = "dropdown hide";
            document.getElementById("Signup").className = "dropdown hide";
            document.getElementById("userfullname").innerText =
                this.first_name + " " + this.last_name;
            document.getElementById("logoutdrp").className = "dropdown";
        }
    };
    TopBarComponent.prototype.logout = function () {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("first_name");
        sessionStorage.removeItem("last_name");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("mobile_number");
        localStorage.clear();
        location.reload();
    };
    // scroll(scrillid){
    //     $('html, body').animate({
    //         scrollTop: $(scrillid).offset().top
    //     },300);
    // }
    TopBarComponent.prototype.scroll = function (scrillid) {
        if (sessionStorage.getItem("token")) {
            var x = location.href;
            if (x.endsWith("/home")) {
                $('html, body').animate({
                    scrollTop: $(scrillid).offset().top
                }, 300);
            }
            else {
                location.href = "./home" + scrillid;
            }
        }
        else {
            var x = location.href;
            // alert(x)
            if (x.endsWith("/faq") || x.endsWith("/terms")) {
                location.href = "./home" + scrillid;
            }
            else {
                $('html, body').animate({
                    scrollTop: $(scrillid).offset().top
                }, 300);
            }
        }
    };
    TopBarComponent.prototype.scrollto = function (scrillid) {
        $('html, body').animate({
            scrollTop: $(scrillid).offset().top
        }, 300);
    };
    TopBarComponent.prototype.toggleLogin = function () {
        // console.log("tbb4login:"+this.visible_vars["login_visible"])
        this.visible_vars["login_visible"] = !this.visible_vars["login_visible"];
        // this.is_login_visible_emit.emit(this.visible_vars["login_visible"]);
        // console.log("tblogin:"+this.visible_vars["login_visible"])
        this.visible_vars_emit.emit({ "login_visible": this.visible_vars["login_visible"], "signup_visible": false, "forgot_pass_visible": false });
    };
    TopBarComponent.prototype.toggleSignup = function () {
        this.visible_vars["signup_visible"] = !this.visible_vars["signup_visible"];
        // this.is_signup_visible_emit.emit(this.visible_vars["signup_visible"]);
        this.visible_vars_emit.emit({ "login_visible": false, "signup_visible": this.visible_vars["signup_visible"], "forgot_pass_visible": false });
    };
    return TopBarComponent;
}());
export { TopBarComponent };

<div class="form-popup change-pswd collapse" id="changepassword">


	<!--New1 -->

	<div class="loginpanel">
		<div class="login-header">
			<h3>Change Password </h3>
		</div>
		<div class="login-form">

			<!-- <div class="help-form border_blue">
				<div>
					<input [type]="ispassword1?'password':'text'" class="" placeholder="OldPassword" id="Password1" [(ngModel)]="form_data.old_password">
					<div class="checkbox">
						<input type="checkbox" id="showpswd1" class="spaswd" (change)="ispassword1=!ispassword1">
						<p class="showpswd">
							<label for="showpswd1">
								Show Password
  							</label>
						</p>
					</div>
				</div>
			</div> -->
			<div class="help-form takewidth">
				<div class="fnewpass">
					<input [type]="ispassword2?'password':'text'" class="" placeholder="NewPassword" id="Password2" [(ngModel)]="form_data.new_password">
					<div class="checkbox">
						<input type="checkbox" id="showpswd2" class="spaswd" (change)="ispassword2=!ispassword2">
						<p class="showpswd">
							<label for="showpswd2">
								Show Password
  							</label>
						</p>
					</div>
				</div>
			<!-- </div>
			<div class="help-form "> -->
				<div class="cnewpass">
					<input [type]="ispassword3?'password':'text'" class="" placeholder="ConfirmPassword" id="Password3" [(ngModel)]="form_data.confirm_password">
					<div class="checkbox">
						<input type="checkbox" id="showpswd3" class="spaswd" (change)="ispassword3=!ispassword3">
						<p class="showpswd">
							<label for="showpswd3">
								Show Password
  							</label>
						</p>
					</div>
				</div>
			</div>

		</div>
		<br>
		<div class="text-danger">
			{{validation_msg}}
		</div>
	</div>


	<div class="changepass">
		<div class="download-app">
			<a class="btn transparent">
				<div class="download-app-btn">
					<button class="btn changepassbutton" (click)="changePassword()">Save</button>
				</div>
			</a>
			<a class="btn transparent">
				<div class="download-app-btn" data-toggle="collapse" data-target="#changepassword">
					<button class="btn changepassbutton">Cancel</button>
				</div>
			</a>
		</div>
		<a class="btnclose" data-toggle="collapse" data-target="#changepassword"><i class="fa fa-close"></i></a>
	</div>

</div>
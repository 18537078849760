/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./help-form";
import * as i2 from "../api.service";
var styles_HelpForm = [];
var RenderType_HelpForm = i0.ɵcrt({ encapsulation: 2, styles: styles_HelpForm, data: {} });
export { RenderType_HelpForm as RenderType_HelpForm };
export function View_HelpForm_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_HelpForm_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "help-form", [], null, null, null, View_HelpForm_0, RenderType_HelpForm)), i0.ɵdid(1, 638976, null, 0, i1.HelpForm, [i2.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpFormNgFactory = i0.ɵccf("help-form", i1.HelpForm, View_HelpForm_Host_0, {}, {}, []);
export { HelpFormNgFactory as HelpFormNgFactory };


			<div class="forget-pswd collapse" id="otpverification">
				<div class="pswd">
    				<div class="login-header">
    					<h3>Verification</h3>
						<p>Your one time password will be send to your registered {{(otp_by_email==true)?"email":"mobile number"}} </p>
					</div>
    					<div class="login-form">
    					<div class="rightpad" style="border-bottom: 2px solid #35a8df;" class="col-md-6">
    						<input type="text"  [(ngModel)]="num1" name="num1" id="num1" class="letter" maxlength="1"> 
							<input type="text" [(ngModel)]="num2" name="num2"  id="num2" class="letter" maxlength="1">
							<input type="text" [(ngModel)]="num3" name="num3" id="num3" class="letter" maxlength="1">
							<input type="text" [(ngModel)]="num4" name="num4" id="num4" class="letter" maxlength="1">
							<input type="text" [(ngModel)]="num5" name="num5"  id="num5" class="letter" maxlength="1">	
							<input type="text" [(ngModel)]="num6" name="num6"  id="num6" class="letter" maxlength="1">
						</div>
  							<button class="btn submitbutton"  (click)="onVerifiyOTP()">SUBMIT</button>
						</div>
						<div  id="otpverify_error_msg" class="text text-danger" ></div> 
						<div (click)="back()"  style="margin-top: 15px;"><a><i class="fa fa-angle-left" style="margin: 10px;"></i>BACK</a></div>
						<input type="hidden" id="from_route">
						<input type="hidden" id="from_mobile">
						<input type="hidden" id="from_isd">

				</div>
				<a class="btnclose" id="otp-close"><i class="fa fa-close"></i></a>
			</div>
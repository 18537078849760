var FooterComponent //implements OnInit 
 = /** @class */ (function () {
    function FooterComponent() {
    }
    FooterComponent.prototype.scroll = function (scrillid) {
        if (sessionStorage.getItem("token")) {
            var x = location.href;
            if (x.endsWith("/home")) {
                $('html, body').animate({
                    scrollTop: $(scrillid).offset().top
                }, 300);
            }
            else {
                location.href = "./home" + scrillid;
            }
        }
        else {
            // $('html, body').animate({
            //   scrollTop: $(scrillid).offset().top
            // }, 300);
            var x = location.href;
            // alert(x)
            if (x.endsWith("/faq") || x.endsWith("/terms")) {
                location.href = "./home" + scrillid;
            }
            else {
                $('html, body').animate({
                    scrollTop: $(scrillid).offset().top
                }, 300);
            }
        }
    };
    FooterComponent.prototype.hideLogin = function (scrillid) {
        document.getElementById("register-popup").className = "form-popup registerpopup";
        document.getElementById("login-popup").className = "form-popup collapse";
        document.getElementById("forgetpassword").className = "forget-pswd collapse";
        document.getElementById("otpverification").className = "forget-pswd collapse";
        $('html, body').animate({
            scrollTop: $(scrillid).offset().top
        }, 300);
        document.getElementById("FirstName").value = "";
        document.getElementById("LastName").value = "";
        document.getElementById("Email").value = "";
        document.getElementById("signupmobile").value = "";
        document.getElementById("signPassword").value = "";
    };
    return FooterComponent;
}());
export { FooterComponent };

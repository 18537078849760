import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment'

@Component({
  selector: 'redeem-component',
  templateUrl: './redeem-component.html',
//   styleUrls: ['./login.css']
})
export class RedeemComponent //implements OnInit 
{

  constructor(private as: ApiService) { }
  gift_card_list=[];
  backend_url=environment.dp_domain;
  ngOnInit(){
    
    this.as.getData("/gift_cards/get_gift_cards_list?country_id=2").subscribe((result)=>{
      
      //  console.log("reddddddd==========>"+JSON.stringify(result))
        // result=result["values"];
        if(result["status"]){
          this.gift_card_list=result["values"];
        // result.forEach((element)=>{
        //   this.country_code_menu["items"].push(
        //     {"img":environment.dp_domain+element.flag_image,
        //     "value":element.country_code,
        //     "length":element.mobile_number_length,
        //   "name":element.name}
        //     )
        // })
          // console.log("valsu:",this.gift_card_list)
        }
      });
  }
}
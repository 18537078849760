<div class="form-popup registerpopup collapse" id="register-popup">
	<div class="loginpanel">
		<div class="login-header">
			<div class="fulllenthwidth">
				<h3 class="leftblock">Register</h3>
				<p class="rightblock">All the fields are mandatory* </p>
			</div>
		</div>
		<div class="register">
			<div (keydown)="keyDownFunction($event)" class="register-form">
				<div class="mobile border_blue">

					<input type="text" class="" (keypress)="validatetext($event)" placeholder="First Name" id="FirstName" [(ngModel)]="form_data.first_name">
					<input type="text" class="" (keypress)="validatetext($event)" placeholder="Last Name" id="LastName" [(ngModel)]="form_data.last_name">
					<input type="text" class="email" placeholder="Email" id="Email" [(ngModel)]="form_data.email">
				</div>
				<div class="mobile border_blue" style="width: 100%;">
					<label class="dropdown" id="countrycodedrp" style="padding-top:6px;">
						<!--<div class="selectedcountry" data-toggle="dropdown" aria-expanded="false">
    									<img src="../../../assets/icons/usa-flag.png" alt="">+971
    									<i class="fa fa-angle-down"></i>
    								</div>
   				 					<ul class="dropdown-menu">    						
      									<li (click)="setCtrCode('971')"><a ><img src="../../../assets/icons/usa-flag.png" alt="">+971</a></li>   
      									<li (click)="setCtrCode('91')"><a ><img src="../../../assets/images/ind.jpg" alt="">+91</a></li>    
    								</ul>-->

						<div class="selectedcountry" data-toggle="dropdown" aria-expanded="false">
							<img [src]="country_code_menu['selected']['img']" alt="">+{{country_code_menu['selected']['value']}}
							<i class="fa fa-angle-down"></i>
						</div>
						<ul class="dropdown-menu">
							<span *ngFor="let menu of country_code_menu['items']">
								<li (click)="setCtrCode(menu)"><a><img [src]="menu.img" alt="">+{{menu.value}}</a></li>
							</span>
						</ul>
					</label>
					<input type="text" class="" placeholder="Enter Mobile Number" id="signupmobile" style="width: 25%;" [(ngModel)]="form_data.mobile_number"
					 (keypress)="validateMobileno($event)" [maxlength]="country_code_menu['selected']['length']">
					&nbsp; &nbsp;
					<input type="password" class="" placeholder="Password" id="signPassword" style="width: 25%;" [(ngModel)]="form_data.password">


					<div style="width: 30%" class="text text-danger">
						<p id="signup_error_msg" class="registernew"></p>
					</div>


				</div>
				<div class="row">
					<div class="col-sm-6" style="white-space: nowrap;">
					<input type="checkbox" id="termsCheckBox" style="float:left">
					<label for="termsCheckBox" style="margin-top: 3px;padding-left:5px"> I agree to the <a href="#">Terms & Conditions</a></label>
				</div>
				<div class="col-sm-6" style="white-space: nowrap;">
					<input type="checkbox" id="above18CheckBox" style="float:left">
					<label for="above18CheckBox" style="margin-top: 3px;padding-left:5px"> I confirm I'm 18 years old or older</label>
				</div>
					<br>
					<p>
						To verify your account, we'll send you a one-time code to use in the next step, please choose how  you want to receive this code.
					</p>
					<span style="white-space: nowrap">
					<input type="radio" style="-webkit-appearance:checkbox" id="otp_by_mail" name="otp_choice" checked>
					<label for="otp_by_mail"> By Email </label>
				</span>
					<b style="padding:0px 100px">OR</b> 
					<span style="white-space: nowrap">
					<input type="radio" style="-webkit-appearance:checkbox" id="otp_by_sms" name="otp_choice">
					<label for="otp_by_sms"> By phone (SMS) </label>
				</span>
				</div>

			</div>

			<button class="btn submitbutton" (click)="register()">SUBMIT</button>


		</div>

	</div>

	<div class="loginwith">
		<div class="download-app">
			<a class="btn transparent border_blue">
				<div (click)="tryFacebookLogin()" class="download-app-btn">
					<img src="../../../assets/icons/fb.png" alt="google play logo">
					<span>
						<p class="text-blue">SIGN IN WITH FACEBOOK</p>
					</span>
				</div>
			</a>
			<a class="btn transparent border_red">
				<div (click)="tryGoogleLogin()" class="download-app-btn">
					<img src="../../../assets/icons/google.png" alt="apple logo">
					<span>
						<p class="text-red">SIGN IN WITH GOOGLE</p>
					</span>
				</div>
			</a>
		</div>
		<a class="btnclose" data-toggle="collapse" data-target="#register-popup" (click)="closePopup()"><i class="fa fa-close"></i></a>
	</div>


</div>
var AboutUsComponent //implements OnInit 
 = /** @class */ (function () {
    function AboutUsComponent() {
    }
    AboutUsComponent.prototype.topFunction = function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    return AboutUsComponent;
}());
export { AboutUsComponent };

import { OnInit, EventEmitter, SimpleChanges } from '@angular/core';
// import { LocalStorageService, LocalStorageModule } from 'angular-2-local-storage';
import { ApiService } from '../../api.service';
import { AuthService } from '../../core/auth.service';
import { environment } from '../../../environments/environment';
import { CountryListService } from '../../core/countrylist.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(window, apiService, authService, country_service) {
        this.window = window;
        this.apiService = apiService;
        this.authService = authService;
        this.country_service = country_service;
        this.login_status = new EventEmitter();
        // @Output() is_visible = new EventEmitter<boolean>();
        this.visible_vars_emit = new EventEmitter();
        this.form_data = {
            "password": "",
            "mobile_number": "",
            "country": "971",
            "mobile": ""
        };
        this.country_code_menu = {
            "selected": {},
            "items": []
        };
        this.show = false;
        this.country_service.onGetData.subscribe(function (res) {
            console.log("country lsit login");
        });
    }
    LoginComponent.prototype.ngOnChanges = function (changes) {
        // console.log("Chg:",JSON.stringify(changes))
        if (changes["country_list"]["currentValue"] != undefined) {
            this.country_code_menu = changes["country_list"]["currentValue"];
            // console.log("Chg:",JSON.stringify(this.country_code_menu))
            this.country_code_menu["selected"] = this.country_code_menu["items"][0];
            // console.log("select:"+JSON.stringify(this.country_code_menu["selected"]))
        }
    };
    LoginComponent.prototype.ngOnInit = function () {
        if (sessionStorage.getItem("token")) {
            document.getElementById("joinforfree").className = "joinforfree collapse";
            document.getElementById("joinforfreefooter").style.visibility = "hidden";
            document.getElementById("logoutimg").src = sessionStorage.getItem("userimage");
            document.getElementById("menu_pts").innerText = sessionStorage.getItem("userpoint");
            // (<HTMLImageElement>document.getElementById("myaccontimg")).src = sessionStorage.getItem("userimage");
        }
        else {
            document.getElementById("joinforfree").className = "joinforfree";
            document.getElementById("joinforfreefooter").style.visibility = "visibil";
        }
        // this.country_code_menu["items"]=[]
        // this.apiService.getCountryListData().subscribe((result)=>{
        //   result=result["values"];
        //   result.forEach((element)=>{
        //     this.country_code_menu["items"].push(
        //       {"img":"https://dpapisit.clubclass.io"+element.flag_image,"value":element.country_code}
        //       )
        //   })
        //   this.country_code_menu["selected"]=this.country_code_menu["items"][0]
        // });
    };
    LoginComponent.prototype.keyDownFunction = function (event) {
        if (event.keyCode == 13) {
            this.login();
            // rest of your code
        }
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        if (this.form_data.mobile_number == "" || !this.validateMobileNoSubmit(this.form_data.mobile_number)) {
            document.getElementById("login_error_msg").innerText = "Please Enter Valid Mobile No.";
        }
        else if (this.form_data.password == "") {
            document.getElementById("login_error_msg").innerText = "Please Enter Password";
        }
        else {
            document.getElementById("login_error_msg").innerText = "";
            this.form_data["mobile"] = this.form_data["country"] + this.form_data["mobile_number"];
            sessionStorage.setItem("loginisd", this.form_data["country"]);
            sessionStorage.setItem("loginmobileno", this.form_data["mobile_number"]);
            //console.log(JSON.stringify(this.form_data))
            var login_details = {
                "country_code": this.form_data["country"],
                "mobile": this.form_data["mobile_number"],
                "password": this.form_data.password
            };
            this.apiService.getPostData(environment.dp_member_login, login_details).map(function (res) {
                //console.log("data:"+JSON.stringify(res))
                if (res["status"] == true) {
                    _this.login_status.emit(true);
                    document.getElementById("login").className = "dropdown hide";
                    document.getElementById("Signup").className = "dropdown hide";
                    document.getElementById("userfullname").innerText =
                        res["values"][0]["first_name"] + " " + res["values"][0]["last_name"];
                    document.getElementById("logoutdrp").className = "dropdown";
                    sessionStorage.setItem("token", res["values"][0]["token"]);
                    sessionStorage.setItem("first_name", res["values"][0]["first_name"]);
                    sessionStorage.setItem("last_name", res["values"][0]["last_name"]);
                    sessionStorage.setItem("mobile_number", res["values"][0]["mobile_number"]);
                    sessionStorage.setItem("email", res["values"][0]["email"]);
                    document.getElementById("login-popup").className = "form-popup collapse";
                    document.getElementById("joinforfree").className = "joinforfree collapse";
                    document.getElementById("joinforfreefooter").style.visibility = "hidden";
                    _this.getPoint();
                    var userdta = {
                        "name": res["values"][0]["first_name"] + res["values"][0]["last_name"],
                        "mobile": res["values"][0]["mobile_number"],
                        "email": res["values"][0]["email"]
                    };
                    _this.apiService.sharedCurrentData(userdta);
                }
                else {
                    document.getElementById("login_error_msg").innerText = res.message;
                }
            })
                .subscribe();
        }
    };
    LoginComponent.prototype.myFunction = function () {
        this.show = !this.show;
    };
    LoginComponent.prototype.tryFacebookLogin = function () {
        var _this = this;
        this.authService.doFacebookLogin()
            .then(function (res) {
            console.log("this.authService.tryFacebookLogin()=>" + JSON.stringify(res));
            var facebookUID = res.user.uid;
            var jsonreq = {
                "provider": "facebook",
                "uid": facebookUID
            };
            console.log("==========jsonreq=====>" + JSON.stringify(jsonreq));
            _this.apiService.getPostData(environment.dp_mem_social_login, jsonreq).map(function (facebookres) {
                console.log("==========facebookres=====>" + JSON.stringify(facebookres));
                if (facebookres["status"] == true) {
                    _this.login_status.emit(true);
                    document.getElementById("login").className = "dropdown hide";
                    document.getElementById("Signup").className = "dropdown hide";
                    document.getElementById("userfullname").innerText =
                        facebookres["values"][0]["first_name"] + " " + facebookres["values"][0]["last_name"];
                    document.getElementById("logoutdrp").className = "dropdown";
                    sessionStorage.setItem("token", facebookres["values"][0]["token"]);
                    sessionStorage.setItem("first_name", facebookres["values"][0]["first_name"]);
                    sessionStorage.setItem("last_name", facebookres["values"][0]["last_name"]);
                    sessionStorage.setItem("mobile_number", facebookres["values"][0]["mobile_number"]);
                    sessionStorage.setItem("email", facebookres["values"][0]["email"]);
                    document.getElementById("login-popup").className = "form-popup collapse";
                    document.getElementById("joinforfree").className = "joinforfree collapse";
                    document.getElementById("joinforfreefooter").style.visibility = "hidden";
                    _this.getPoint();
                    var userdta = {
                        "name": facebookres["values"][0]["first_name"] + facebookres["values"][0]["last_name"],
                        "mobile": facebookres["values"][0]["mobile_number"],
                        "email": facebookres["values"][0]["email"]
                    };
                    _this.apiService.sharedCurrentData(userdta);
                }
                else {
                    document.getElementById("login_error_msg").innerText = facebookres.message;
                }
            }).subscribe();
        });
    };
    LoginComponent.prototype.tryGoogleLogin = function () {
        var _this = this;
        this.authService.doGoogleLogin()
            .then(function (res) {
            //console.log("this.authService.doGoogleLogin()>"+JSON.stringify(res))
            var gmailUID = res.user.uid;
            var jsonreq = {
                "provider": "google",
                "uid": gmailUID
            };
            console.log("this.authService.doGoogleLogin()>" + gmailUID);
            _this.apiService.getPostData(environment.dp_mem_social_login, jsonreq).map(function (socialLogin) {
                console.log("==========tryGoogleLogin=====>" + JSON.stringify(socialLogin));
                if (socialLogin["status"] == true) {
                    _this.login_status.emit(true);
                    document.getElementById("login").className = "dropdown hide";
                    document.getElementById("Signup").className = "dropdown hide";
                    document.getElementById("userfullname").innerText =
                        socialLogin["values"][0]["first_name"] + " " + socialLogin["values"][0]["last_name"];
                    document.getElementById("logoutdrp").className = "dropdown";
                    sessionStorage.setItem("token", socialLogin["values"][0]["token"]);
                    sessionStorage.setItem("first_name", socialLogin["values"][0]["first_name"]);
                    sessionStorage.setItem("last_name", socialLogin["values"][0]["last_name"]);
                    sessionStorage.setItem("mobile_number", socialLogin["values"][0]["mobile_number"]);
                    sessionStorage.setItem("email", socialLogin["values"][0]["email"]);
                    document.getElementById("login-popup").className = "form-popup collapse";
                    document.getElementById("joinforfree").className = "joinforfree collapse";
                    document.getElementById("joinforfreefooter").style.visibility = "hidden";
                    _this.getPoint();
                    var userdta = {
                        "name": socialLogin["values"][0]["first_name"] + socialLogin["values"][0]["last_name"],
                        "mobile": socialLogin["values"][0]["mobile_number"],
                        "email": socialLogin["values"][0]["email"]
                    };
                    _this.apiService.sharedCurrentData(userdta);
                }
                else {
                    document.getElementById("login_error_msg").innerText = socialLogin.message;
                }
            }).subscribe();
        });
    };
    LoginComponent.prototype.getPoint = function () {
        this.apiService.getPointsData(environment.dp_member_point, sessionStorage.getItem("token")).map(function (res) {
            //console.log("data:"+JSON.stringify(res));
            if (res["status"] == true) {
                document.getElementById("menu_pts").innerText =
                    res["values"]["total_points"];
                sessionStorage.setItem("userpoint", res["values"]["total_points"]);
                if (res.values.profile_pic != "" && res.values.profile_pic != null) {
                    // alert("lsjhl");
                    document.getElementById("logoutimg").src =
                        "https://dpapiuat.clubclass.io" +
                            res["values"]["profile_pic"].toString().substring(1, res["values"]["profile_pic"].toString().length);
                    sessionStorage.setItem("userimage", "https://dpapiuat.clubclass.io" +
                        res["values"]["profile_pic"].toString().substring(1, res["values"]["profile_pic"].toString().length));
                }
                else {
                    sessionStorage.setItem("userimage", "./../../../assets/icons/login.png");
                }
            }
        }).subscribe();
    };
    LoginComponent.prototype.forgetPassword = function () {
        this.visible_vars_emit.emit({ "login_visible": false, "signup_visible": false, "forgot_pass_visible": true });
        // (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup collapse";
        // (<HTMLDivElement>document.getElementById("forgetpassword")).className = "forget-pswd ";
        // (<HTMLInputElement>document.getElementById("mobileno")).value = "";
    };
    LoginComponent.prototype.setCtrCode = function (selected) {
        this.country_code_menu.selected = selected;
        this.form_data["country"] = selected.value;
        this.form_data.mobile_number = "";
        //console.log(JSON.stringify(this.form_data))
    };
    LoginComponent.prototype.validateMobileNoSubmit = function (value) {
        // var integerOnly =;
        var match = value.toString().match(/[0-9\.]/g);
        //console.log(">>>"+match)
        if (match == null)
            return false;
        else
            return true;
    };
    LoginComponent.prototype.validateMobileno = function (evt) {
        // alert(evt.which)
        var theEvent = evt || this.window.event;
        var regex = /[0-9]/;
        if (!regex.test(evt.key) && evt.which != 8 && evt.which != 0) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault)
                theEvent.preventDefault();
        }
    };
    LoginComponent.prototype.closePopup = function () {
        // setTimeout(()=>{
        // this.is_visible.emit(false);
        this.visible_vars_emit.emit({ "login_visible": false, "signup_visible": false, "forgot_pass_visible": false });
        // },0);
    };
    return LoginComponent;
}());
export { LoginComponent };

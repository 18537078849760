import { Component, Input } from "@angular/core";
import {LoginComponent} from '../topbar/login/login-component'
@Component({
    selector:"home-main",
    templateUrl:"./home-main.html"
})
export class HomeMain{
    @Input() name1;

    @Input() country_list;

    ngOnInit(){
        console.log("HomeMain ============> ",this.name1);
    }
}
import { EventEmitter } from '@angular/core';
var SideBarService = /** @class */ (function () {
    function SideBarService() {
        this.isOpen = false;
        this.change = new EventEmitter();
    }
    SideBarService.prototype.toggle = function () {
        this.isOpen = !this.isOpen;
        this.change.emit(this.isOpen);
    };
    return SideBarService;
}());
export { SideBarService };

import { OnInit, EventEmitter } from '@angular/core';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
var OTPVerifyComponent = /** @class */ (function () {
    function OTPVerifyComponent(apiService) {
        this.apiService = apiService;
        this.login_status = new EventEmitter();
    }
    OTPVerifyComponent.prototype.ngOnInit = function () {
        // this.otp_by_email=sessionStorage.getItem("otp_choice_by_mail");
        // console.log("otp_by email:"+this.otp_by_email)
        // console.log("JSON.parse(sessionStorage.getItem"+JSON.stringify(JSON.parse(sessionStorage.getItem("formdata"))));
    };
    OTPVerifyComponent.prototype.onVerifiyOTP = function () {
        var otproute = document.getElementById("from_route").value;
        console.log("<<<<<<<<<<<<<<<<<<<<<<<" + otproute);
        if (otproute == "REG") {
            this.onVerifiyOTPREG();
        }
        else {
            this.onVerifiyOTPFOG();
        }
    };
    OTPVerifyComponent.prototype.onVerifiyOTPFOG = function () {
        var otp = this.num1 + this.num2 + this.num3 + this.num4 + this.num5 + this.num6;
        document.getElementById("fnewpass").value = "";
        document.getElementById("cnewpass").value = "";
        if (otp.length != 6) {
            document.getElementById("otpverify_error_msg").innerText = "Please Enter OTP.";
        }
        else {
            document.getElementById("otpverify_error_msg").innerText = "";
            var isd = document.getElementById("from_isd").value;
            var mobileno = document.getElementById("from_mobile").value;
            //  console.log("============++++>"+mobileno + "akhi"+isd+"")
            var VerifiOTP = {
                "country_code": mobileno,
                "otp": otp,
                "mobile": mobileno
            };
            this.apiService.getPostData(environment.dp_verify_otp, VerifiOTP).map(function (res) {
                if (res.status) {
                    document.getElementById("otpverification").className = "forget-pswd collapse";
                    document.getElementById("changeforgotpassword").className = "form-popup";
                }
                else {
                    document.getElementById("otpverify_error_msg").innerText = res.message;
                }
            }).subscribe();
            // (<HTMLInputElement>document.getElementById("FirstName")).value = "";
            // (<HTMLInputElement>document.getElementById("LastName")).value = "";
            // (<HTMLInputElement>document.getElementById("Email")).value = "";
            // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
            // (<HTMLInputElement>document.getElementById("signPassword")).value = "";
            // (<HTMLInputElement>document.getElementById("signupmobile")).value = "";
            // (<HTMLInputElement>document.getElementById("showpswd")).value = "";
            document.getElementById("login_error_msg").innerText = "";
        }
    };
    OTPVerifyComponent.prototype.onVerifiyOTPREG = function () {
        var _this = this;
        var otp = this.num1 + this.num2 + this.num3 + this.num4 + this.num5 + this.num6;
        if (otp.length != 6) {
            document.getElementById("otpverify_error_msg").innerText = "Please Enter OTP.";
        }
        else {
            document.getElementById("otpverify_error_msg").innerText = "";
            var password = document.getElementById("signPassword").value;
            var form_data = JSON.parse(sessionStorage.getItem("formdata"));
            form_data.mobile = form_data.mobile_number;
            form_data.country_code = form_data.country;
            var VerifiOTP = {
                "otp": otp,
                "country_code": form_data.country,
                "mobile": form_data.mobile_number
            };
            //  console.log("https://dpapiuat.clubclass.io/api/client/register"+JSON.stringify(form_data))
            this.apiService.getPostData(environment.dp_verify_otp, VerifiOTP).map(function (res) {
                if (res.status) {
                    _this.apiService.getPostData(environment.dp_mem_register, form_data).map(function (res) {
                        if (res.status) {
                            console.log("environment.dp_verify_otp" + JSON.stringify(res));
                            document.getElementById("otpverification").className = "forget-pswd collapse";
                            // (<HTMLDivElement>document.getElementById("login-popup")).className = "form-popup";
                            _this.login(form_data.country, form_data.mobile_number, form_data.password);
                            // sessionStorage.clear();
                        }
                        else {
                        }
                    })
                        .subscribe();
                }
                else {
                    document.getElementById("otpverify_error_msg").innerText = res.message;
                }
            }).subscribe();
        }
    };
    OTPVerifyComponent.prototype.login = function (isd, mobile, pass) {
        var _this = this;
        var login_details = {
            "country_code": isd,
            "mobile": mobile,
            "password": pass
        };
        this.apiService.getPostData(environment.dp_member_login, login_details).map(function (res) {
            //console.log("data:"+JSON.stringify(res))
            if (res["status"] == true) {
                _this.login_status.emit(true);
                document.getElementById("login").className = "dropdown hide";
                document.getElementById("Signup").className = "dropdown hide";
                document.getElementById("userfullname").innerText =
                    res["values"][0]["first_name"] + " " + res["values"][0]["last_name"];
                document.getElementById("logoutdrp").className = "dropdown";
                sessionStorage.setItem("token", res["values"][0]["token"]);
                sessionStorage.setItem("first_name", res["values"][0]["first_name"]);
                sessionStorage.setItem("last_name", res["values"][0]["last_name"]);
                sessionStorage.setItem("mobile_number", res["values"][0]["mobile_number"]);
                sessionStorage.setItem("email", res["values"][0]["email"]);
                document.getElementById("login-popup").className = "form-popup collapse";
                document.getElementById("joinforfree").className = "joinforfree collapse";
                document.getElementById("joinforfreefooter").style.visibility = "hidden";
                _this.getPoint();
                var userdta = {
                    "name": res["values"][0]["first_name"] + res["values"][0]["last_name"],
                    "mobile": res["values"][0]["mobile_number"],
                    "email": res["values"][0]["email"]
                };
                _this.apiService.sharedCurrentData(userdta);
            }
            else {
                document.getElementById("signup_error_msg").innerText = res.message;
            }
        })
            .subscribe();
    };
    OTPVerifyComponent.prototype.getPoint = function () {
        this.apiService.getPointsData(environment.dp_member_point, sessionStorage.getItem("token")).map(function (res) {
            //console.log("data:"+JSON.stringify(res));
            if (res["status"] == true) {
                document.getElementById("menu_pts").innerText =
                    res["values"]["total_points"];
                sessionStorage.setItem("userpoint", res["values"]["total_points"]);
                if (res.values.profile_pic != "" && res.values.profile_pic != null) {
                    // alert("lsjhl");
                    document.getElementById("logoutimg").src =
                        "https://dpapiuat.clubclass.io" +
                            res["values"]["profile_pic"].toString().substring(1, res["values"]["profile_pic"].toString().length);
                    sessionStorage.setItem("userimage", "https://dpapiuat.clubclass.io" +
                        res["values"]["profile_pic"].toString().substring(1, res["values"]["profile_pic"].toString().length));
                }
                else {
                    sessionStorage.setItem("userimage", "./../../../assets/icons/login.png");
                }
            }
        }).subscribe();
    };
    OTPVerifyComponent.prototype.back = function () {
        var otproute = document.getElementById("from_route").value;
        if (otproute == "REG") {
            document.getElementById("register-popup").className = "form-popup registerpopup";
            document.getElementById("otpverification").className = "forget-pswd collapse";
        }
        else {
            document.getElementById("otpverification").className = "forget-pswd collapse";
            document.getElementById("forgetpassword").className = "forget-pswd ";
        }
    };
    return OTPVerifyComponent;
}());
export { OTPVerifyComponent };

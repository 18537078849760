
			<div class="form-popup collapse" id="login-popup" >
				<div class="loginpanel">
					<div class="login-header"><h3>Login </h3></div>
					
    					<div class="login-form" (keydown)="keyDownFunction($event)">
								<label class="dropdown" id="countrycodedrp">
    								
										<div class="selectedcountry" data-toggle="dropdown" aria-expanded="false">
											<img [src]="country_code_menu['selected']['img']" alt="">+{{country_code_menu['selected']['value']}}
											<i class="fa fa-angle-down"></i>
										</div>
											<ul class="dropdown-menu">    
											<span *ngFor="let menu of country_code_menu['items']"	>				
												  <li (click)="setCtrCode(menu)"><a ><img [src]="menu.img" alt="">+{{menu.value}}</a></li> 
											</span>
										</ul>
									  </label>
    						<div class="mobile border_blue">
								
  								<input type="text" id="loginnum" class="" placeholder="Enter Mobile Number" style="border: none;" 
								  [(ngModel)]="form_data.mobile_number"  (keypress)="validateMobileno($event)">
							</div>
							<div class="help-form border_blue"> 	
								<div>
								<input  [type]="show ? 'text' : 'password'" id="loginpasword" class="" placeholder="Password" id="Password" [(ngModel)]="form_data.password">
								<div class="checkbox">
										<input id="showpswd" class="spaswd" (click)="myFunction()" type="checkbox" value="">  
									<p class="showpswd"><label  for="showpswd">
  										Show Password
  									</label></p>
								</div>
    							</div>
  							</div>
  							<button type="submit" class="btn submitbutton" (click)="login()">LOGIN</button>
						</div>
				
						<div id="login_error_msg" class="text text-danger">

						</div>
					<div  class="foregetpswdlink">
						<a  (click)="forgetPassword()"   data-toggle="collapse" data-target="#forgetpassword">Forgot Password ? </a>
					</div>  
				</div>
				<div class="loginwith">
    				<div class="download-app">
						<a class="btn transparent border_blue">
						<div class="download-app-btn">
							<img src="../../../assets/icons/fb.png" alt="google play logo">							
							<span>
								<p  (click)="tryFacebookLogin()" class="text-blue">SIGN IN WITH FACEBOOK</p>								
							</span>
						</div>								
					</a>
					<a class="btn transparent border_red">
						<div class="download-app-btn">
							<img src="../../../assets/icons/google.png" alt="apple logo">
							<span>								
								<p  (click)="tryGoogleLogin()"  class="text-red">SIGN IN WITH GOOGLE</p>
							</span>
						</div>								
					</a>
					</div>
					<a class="btnclose"  data-toggle="collapse" data-target="#login-popup" (click)="closePopup()"><i class="fa fa-close"></i></a>
				</div>				
			</div>
import { ApiService } from './api.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
var AppComponent = /** @class */ (function () {
    function AppComponent(platformId, apiService) {
        var _this = this;
        this.apiService = apiService;
        this.isbrowser = false;
        this.temp_country_list = {
            "selected": {},
            "items": []
        };
        this.visible_vars = {
            "login_visible": false,
            "signup_visible": false,
            "forgot_pass_visible": false
        };
        this.title = 'DubaiPointsWeb';
        if (isPlatformBrowser(platformId)) {
            this.isbrowser = true;
        }
        try {
            this.apiService.getCountryListData().subscribe(function (result) {
                result = result["values"];
                if (result.forEach)
                    result.forEach(function (element) {
                        _this.temp_country_list["items"].push({ "img": environment.dp_domain + element.flag_image,
                            "value": element.country_code,
                            "length": element.mobile_number_length });
                    });
                _this.temp_country_list["selected"] = _this.temp_country_list["items"][0];
                _this.country_list = _this.temp_country_list;
                console.log("app country init ");
            });
        }
        catch (e) { }
    }
    AppComponent.prototype.getLoginStatus = function (event) {
        this.login_status = event;
        console.log("event == > ", event);
    };
    AppComponent.prototype.toggleLogin = function (event) {
        // this.visible_vars["login_visible"]=event;
        this.visible_vars = { "login_visible": event, "signup_visible": false, "forgot_pass_visible": false };
    };
    AppComponent.prototype.toggleSignup = function (event) {
        // this.visible_vars["login_visible"]=event;
        this.visible_vars = { "login_visible": false, "signup_visible": event, "forgot_pass_visible": false };
    };
    AppComponent.prototype.toggleGlobal = function (visible_vars_temp) {
        // console.log("app vis:",visible_vars_temp)
        this.visible_vars = visible_vars_temp;
    };
    return AppComponent;
}());
export { AppComponent };

import { Http, Headers } from '@angular/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
// import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.sharedDataSource = new BehaviorSubject('');
        this.currentSharedData = this.sharedDataSource.asObservable();
    }
    ApiService.prototype.getPutData = function (url, json) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_AGENT", "Website");
        headers.append("USER_TOKEN", "377d9760-d69c-11e8-ac13-59b1b9f58627");
        // headers.append("","");
        return this.http.put(url, json, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getPostData = function (url, json) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_AGENT", "Website");
        headers.append("USER_TOKEN", "377d9760-d69c-11e8-ac13-59b1b9f58627");
        // headers.append("","");
        return this.http.post(url, json, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.savehelpData = function (url, json) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_AGENT", "Website");
        // headers.append("","");
        console.log("=====savehelpData===============>", json);
        return this.http.post(url, json, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getOTP = function (url, json, USER_TOKEN) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_AGENT", "Website");
        headers.append("USER_TOKEN", USER_TOKEN);
        // headers.append("","");
        return this.http.post(url, json, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getPointsData = function (url, user_token) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        // headers.append("Content-Type","application/json")
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_TOKEN", user_token);
        return this.http.get(url, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getCountryListData = function () {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        // headers.append("Content-Type","application/json")
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_TOKEN", "377d9760-d69c-11e8-ac13-59b1b9f58627");
        // return this.http.get("https://dpapisit.clubclass.io/api/client/profile/get_country_list?version=",{headers:headers})
        return this.http.get(environment.dp_domain + "/api/client/profile/get_country_list?version=", { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getData = function (action) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("TENANT_TOKEN", "65eb4500-d608-11e8-b672-b5367e9f78ec");
        headers.append("API_TOKEN", "90c03ae0-0d97-11e8-8953-9793e00dcdf2");
        headers.append("USER_AGENT", "Website");
        headers.append("USER_TOKEN", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6NjMsInRlbmFudF9pZCI6MSwiZmlyc3RfbmFtZSI6InByYXZpbiIsImxhc3RfbmFtZSI6Im1ha2F3YW5hIiwibW9iaWxlX251bWJlciI6Ijc3OTg0NzgyOTAiLCJlbWFpbCI6InByYXZpbkBnbWFpbC5jb20iLCJjb3VudHJ5X2NvZGUiOiI5MSIsImlhdCI6MTU0NTY1MTM1NSwiZXhwIjoxNTc3MTg3MzU1fQ.a-rUqGYsmNRpjaxQqsbUTl5D6bOE8C1KGehUohd2Zng");
        // headers.append("","");
        return this.http.get(environment.dp_domain + environment.dp_service_path + action, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.sharedCurrentData = function (data) {
        this.sharedDataSource.next(data);
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.Http)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };

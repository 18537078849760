/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../core/countrylist.service";
import * as i2 from "../api.service";
import * as i3 from "./top-bar-component";
var styles_TopBarComponent = [];
var RenderType_TopBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopBarComponent, data: {} });
export { RenderType_TopBarComponent as RenderType_TopBarComponent };
export function View_TopBarComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_TopBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "top-bar-component", [], null, null, null, View_TopBarComponent_0, RenderType_TopBarComponent)), i0.ɵprd(4608, null, i1.CountryListService, i1.CountryListService, [i2.ApiService]), i0.ɵdid(2, 638976, null, 0, i3.TopBarComponent, [i0.PLATFORM_ID, i2.ApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TopBarComponentNgFactory = i0.ɵccf("top-bar-component", i3.TopBarComponent, View_TopBarComponent_Host_0, { country_list: "country_list", visible_vars: "visible_vars" }, { visible_vars_emit: "visible_vars_emit" }, []);
export { TopBarComponentNgFactory as TopBarComponentNgFactory };

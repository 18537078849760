/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./login-component";
import * as i4 from "@ng-toolkit/universal";
import * as i5 from "../../api.service";
import * as i6 from "../../core/auth.service";
import * as i7 from "../../core/countrylist.service";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCtrCode(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵted(4, null, ["+", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.img; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_1); }); }
export function View_LoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 55, "div", [["class", "form-popup collapse"], ["id", "login-popup"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 38, "div", [["class", "loginpanel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "login-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Login "])), (_l()(), i0.ɵeld(5, 0, null, null, 30, "div", [["class", "login-form"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.keyDownFunction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "label", [["class", "dropdown"], ["id", "countrycodedrp"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["aria-expanded", "false"], ["class", "selectedcountry"], ["data-toggle", "dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵted(9, null, ["+", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-angle-down"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 6, "div", [["class", "mobile border_blue"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 5, "input", [["class", ""], ["id", "loginnum"], ["placeholder", "Enter Mobile Number"], ["style", "border: none;"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keypress"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.form_data.mobile_number = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (_co.validateMobileno($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(18, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(20, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(21, 0, null, null, 12, "div", [["class", "help-form border_blue"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 5, "input", [["class", ""], ["id", "Password"], ["placeholder", "Password"]], [[8, "type", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.form_data.password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(24, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(26, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(28, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(29, 0, null, null, 4, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 0, "input", [["class", "spaswd"], ["id", "showpswd"], ["type", "checkbox"], ["value", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.myFunction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 2, "p", [["class", "showpswd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 1, "label", [["for", "showpswd"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Show Password "])), (_l()(), i0.ɵeld(34, 0, null, null, 1, "button", [["class", "btn submitbutton"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["LOGIN"])), (_l()(), i0.ɵeld(36, 0, null, null, 0, "div", [["class", "text text-danger"], ["id", "login_error_msg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 2, "div", [["class", "foregetpswdlink"]], null, null, null, null, null)), (_l()(), i0.ɵeld(38, 0, null, null, 1, "a", [["data-target", "#forgetpassword"], ["data-toggle", "collapse"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.forgetPassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Forgot Password ? "])), (_l()(), i0.ɵeld(40, 0, null, null, 15, "div", [["class", "loginwith"]], null, null, null, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 12, "div", [["class", "download-app"]], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, null, null, 5, "a", [["class", "btn transparent border_blue"]], null, null, null, null, null)), (_l()(), i0.ɵeld(43, 0, null, null, 4, "div", [["class", "download-app-btn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(44, 0, null, null, 0, "img", [["alt", "google play logo"], ["src", "../../../assets/icons/fb.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(45, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(46, 0, null, null, 1, "p", [["class", "text-blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryFacebookLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["SIGN IN WITH FACEBOOK"])), (_l()(), i0.ɵeld(48, 0, null, null, 5, "a", [["class", "btn transparent border_red"]], null, null, null, null, null)), (_l()(), i0.ɵeld(49, 0, null, null, 4, "div", [["class", "download-app-btn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(50, 0, null, null, 0, "img", [["alt", "apple logo"], ["src", "../../../assets/icons/google.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(51, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(52, 0, null, null, 1, "p", [["class", "text-red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryGoogleLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["SIGN IN WITH GOOGLE"])), (_l()(), i0.ɵeld(54, 0, null, null, 1, "a", [["class", "btnclose"], ["data-target", "#login-popup"], ["data-toggle", "collapse"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(55, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.country_code_menu["items"]; _ck(_v, 13, 0, currVal_2); var currVal_10 = _co.form_data.mobile_number; _ck(_v, 18, 0, currVal_10); var currVal_19 = _co.form_data.password; _ck(_v, 26, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.country_code_menu["selected"]["img"]; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.country_code_menu["selected"]["value"]; _ck(_v, 9, 0, currVal_1); var currVal_3 = i0.ɵnov(_v, 20).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 20).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 20).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 20).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 20).ngClassValid; var currVal_8 = i0.ɵnov(_v, 20).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 20).ngClassPending; _ck(_v, 15, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = (_co.show ? "text" : "password"); var currVal_12 = i0.ɵnov(_v, 28).ngClassUntouched; var currVal_13 = i0.ɵnov(_v, 28).ngClassTouched; var currVal_14 = i0.ɵnov(_v, 28).ngClassPristine; var currVal_15 = i0.ɵnov(_v, 28).ngClassDirty; var currVal_16 = i0.ɵnov(_v, 28).ngClassValid; var currVal_17 = i0.ɵnov(_v, 28).ngClassInvalid; var currVal_18 = i0.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "login-component", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵdid(1, 638976, null, 0, i3.LoginComponent, [i4.WINDOW, i5.ApiService, i6.AuthService, i7.CountryListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("login-component", i3.LoginComponent, View_LoginComponent_Host_0, { country_list: "country_list" }, { login_status: "login_status", visible_vars_emit: "visible_vars_emit" }, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };

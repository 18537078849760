import { ApiService } from '../../api.service';
var ChangePasswordComponent //implements OnInit 
 = /** @class */ (function () {
    function ChangePasswordComponent(as) {
        this.as = as;
        this.form_data = {
            "mobile_no": "",
            "country": "971",
            "old_password": "",
            "new_password": "",
            "confirm_password": ""
        };
        this.ispassword1 = true;
        this.ispassword2 = true;
        this.ispassword3 = true;
        this.validation_msg = "";
    }
    ChangePasswordComponent.prototype.changePassword = function () {
        if (this.isValid()) { }
        // if(this.form_data.mobile_no == ""){
        //   (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText="Please Enter Mobile Number";
        // }else{
        //   (<HTMLDivElement>document.getElementById("forgot_error_msg")).innerText="";
        //   ////console.log(JSON.stringify(this.form_data))
        //   this.as.getPostData("https://dpapiuat.clubclass.io/api/client/sendotp", {
        //     "mobile": //"971"+
        //     this.form_data.country+this.form_data.mobile_no,
        //     "type": "forgetpassowrd"
        //   }).map(res => {
        //     ////console.log("get:" + JSON.stringify(res));
        //     if(res.status){
        //       (<HTMLDivElement>document.getElementById("otpverification")).className = "forget-pswd";
        //     }
        //   })
        //     // .catch((error: any) => {
        //     //   throw Observable.throw(error);
        //     // })
        //     .subscribe();
        // }
    };
    ChangePasswordComponent.prototype.isValid = function () {
        this.validation_msg = "";
        if (this.form_data.old_password == "") {
            this.validation_msg = "Please enter old password";
            return false;
        }
        else {
            if (this.form_data.new_password == "") {
                this.validation_msg = "Please enter new password";
                return false;
            }
            else {
                if (this.form_data.new_password != this.form_data.confirm_password) {
                    this.validation_msg = "Passwords do not match";
                    return false;
                }
            }
        }
        return true;
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };

<!--comment start <section id="aboutus_section">
	<div class="about_us">
		<div class="header-all">
			<h3>ABOUT US</h3>
		</div>
		<div class="container"> comment here -->
			<!-- <div class="aboutus-description">
						City Points aims to be a city wide loyalty program that encourages residents and tourists to visit various retailers and tourist's attractions in Dubai by offering them a rewards scheme based on geolocation check-ins and websites visits with our partners. Users accumulate points and are able to redeem those in different forms, being a discount on purchase, money-off vouchers or other aspirational rewards the customers choose to take.
				</div> -->
			<!--comment start <div class="aboutus-description">
				<p>City Points is a holding company that specializes in using intuitive technology to create effective
					loyalty programs - coalition, proprietary or hybrid. A Dubai-based venture, we leverage the city’s
					potential for innovation to build exciting products, regionally and globally.</p>
				<p>We are a resilient team that understands a client’s need to hold customer attention for the long
					haul. Since incorporation in 2017, City Points has graduated from the Dubai Future Accelerators
					Cohort 1 & 2 and won GITEX’s Future Star Competition.</p>
				<p>City Points is established as an SME incorporated in the British Virgin Islands.</p>
			</div>
		</div>
		<div class="container aboutus-images">
			<div class="row text-center">
				<div class="col-md-6">
					<div class="aboutimg">
						<img src="../../../assets/images/fouad.jpg" alt="">
					</div>
					<div class="content">
						<h3>Fouad El-Qassem</h3>
						<h4>Chief Executive Officer</h4>
						<a href="https://www.linkedin.com/in/fouadqasem/" target="_blank"><img
								src="../../../assets/images/icon_linkedin.png" alt=""></a>
					</div>
				</div>
				<div class="col-md-6 secondimg">
					<div class="aboutimg">
						<img src="../../../assets/images/sridhar.jpg" alt="">
					</div>
					<div class="content">
						<h3>Sridhar Krishnamurthy</h3>
						<h4> Chief Commercial Officer</h4>
						<a href="https://www.linkedin.com/in/sridhar-krishnamurthy-48a10515/" target="_blank"><img
								src="../../../assets/images/icon_linkedin.png" alt=""></a>
					</div>
				</div>
			</div>
		</div>
	</div>



</section> comment end-->

<section id="aboutus_section">
	<div class="about_us">
		<div class="header-all">
			<h3>GET IN TOUCH</h3>
		</div>
		<div class="container">
			<!-- <div class="aboutus-description">
						City Points aims to be a city wide loyalty program that encourages residents and tourists to visit various retailers and tourist's attractions in Dubai by offering them a rewards scheme based on geolocation check-ins and websites visits with our partners. Users accumulate points and are able to redeem those in different forms, being a discount on purchase, money-off vouchers or other aspirational rewards the customers choose to take.
				</div> -->
			<div class="aboutus-description">
				<p>If you would like to chat or discuss interest, feel free to reach out to us at:
					
					</p>
					<p>Email – support@citypoints.ae </p> 

					<p>Please <a routerLink="/tnc" style="text-decoration: none;">click here</a> to view our programs terms and conditions.</p>
			</div>
		</div>
		<!-- <div class="container aboutus-images">
			<div class="row text-center">
				<div class="col-md-6">
					<div class="aboutimg">
						<img src="../../../assets/images/fouad.jpg" alt="">
					</div>
					<div class="content">
						<h3>Fouad El-Qassem</h3>
						<h4>Chief Executive Officer</h4>
						<a href="https://www.linkedin.com/in/fouadqasem/" target="_blank"><img
								src="../../../assets/images/icon_linkedin.png" alt=""></a>
					</div>
				</div>
				<div class="col-md-6 secondimg">
					<div class="aboutimg">
						<img src="../../../assets/images/sridhar.jpg" alt="">
					</div>
					<div class="content">
						<h3>Sridhar Krishnamurthy</h3>
						<h4> Chief Commercial Officer</h4>
						<a href="https://www.linkedin.com/in/sridhar-krishnamurthy-48a10515/" target="_blank"><img
								src="../../../assets/images/icon_linkedin.png" alt=""></a>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</section>

<button (click)="topFunction()" id="myBtn" title="Go to top" style="background-color: black;"><img src="../../../assets/images/backtotop-arrow.png"
		alt="back to top arrow" /></button>
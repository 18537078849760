/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./carousel-component";
var styles_CarouselComponent = [];
var RenderType_CarouselComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
export function View_CarouselComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "section", [["class", "section banner"], ["id", "mainContent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["href", "javascript:void(0);"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "bannerwrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "Banner Image"], ["class", "bannerimg"], ["src", "../../../assets/images/banner33-min.jpg"]], null, null, null, null, null))], null, null); }
export function View_CarouselComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "carousel-component", [], null, null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i0.ɵdid(1, 49152, null, 0, i1.CarouselComponent, [], null, null)], null, null); }
var CarouselComponentNgFactory = i0.ɵccf("carousel-component", i1.CarouselComponent, View_CarouselComponent_Host_0, {}, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };

import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeMain } from './home-page/home-main'
import { LoginComponent } from './topbar/login/login-component';
import { ForgotPasswordComponent } from './topbar/forgot-password/forgot-password';
import { ChangePasswordComponent } from './topbar/change-password/change-password';
import { ChangeForgotPasswordComponent } from './topbar/change-forgot-password/change-password';
import { SignUpComponent } from './topbar/signup/signup-component';
import { TopBarComponent } from './topbar/top-bar-component';
import { CollectComponent } from './home-page/collect-component';
import { RedeemComponent } from './home-page/redeem-component';
import { CarouselComponent } from './home-page/carousel-component';
import { HelpForm } from './help/help-form';
import { FooterComponent } from './footer/footer';
// import { AccountDetails } from './account-details/account-details';
// import { MyAccount } from "./account-details/my-account";
// import { TransactionHistory } from "./account-details/transaction-history";
import { AboutUsComponent } from './about-us/about-us';

import { OTPVerifyComponent } from './topbar/otp-verify/otp-verify';
import { AngularFireModule } from 'angularfire2';
// import { FAQComponent } from "./faq/faq";
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { HttpModule } from '@angular/http';
import { AuthService } from './core/auth.service';
import { SideBarService } from './help/help.service';
import { CountryListService } from './core/countrylist.service'
import { TncComponent } from './tnc/tnc.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeMain,
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    TopBarComponent,
    CarouselComponent,
    CollectComponent,
    RedeemComponent,
    HelpForm,
    // AccountDetails,
    // MyAccount,
    // TransactionHistory,
    ChangePasswordComponent,
    // FAQComponent,
    AboutUsComponent,

    OTPVerifyComponent,
    FooterComponent,
    ChangeForgotPasswordComponent,
    TncComponent,
  ],
  imports: [
    CommonModule,
    NgtUniversalModule,


    FormsModule,
    HttpModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
  ],
  providers: [AuthService, SideBarService, CountryListService],
})
export class AppModule { }

import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { ApiService } from './api.service'
import { isPlatformBrowser} from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ApiService]
})
export class AppComponent {
  isbrowser:boolean=false;
  temp_country_list={
    "selected":{},
    "items":[
    ]
  };
  country_list;
  visible_vars={
    "login_visible":false,
    "signup_visible":false,
    "forgot_pass_visible":false
  }
  
constructor(@Inject(PLATFORM_ID) platformId: string, private apiService: ApiService){
    if(isPlatformBrowser(platformId)){
      this.isbrowser=true;
    }
    
      try{
    this.apiService.getCountryListData().subscribe((result)=>{
      result=result["values"];
      if(result.forEach)
      result.forEach((element)=>{
        this.temp_country_list["items"].push(
          {"img":environment.dp_domain+element.flag_image,
        "value":element.country_code,
        "length":element.mobile_number_length}
          )
      })
      this.temp_country_list["selected"]=this.temp_country_list["items"][0];
      this.country_list=this.temp_country_list;
      console.log("app country init ")
    });
  }catch(e){}
}

  title = 'DubaiPointsWeb';
  login_status;
  getLoginStatus(event) {
    this.login_status = event;
    console.log("event == > ", event);
  }
  toggleLogin(event){
    // this.visible_vars["login_visible"]=event;
    this.visible_vars= {"login_visible":event,"signup_visible":false,"forgot_pass_visible":false};
  }
  toggleSignup(event){
    // this.visible_vars["login_visible"]=event;
    this.visible_vars= {"login_visible":false,"signup_visible":event,"forgot_pass_visible":false};
  }
  toggleGlobal(visible_vars_temp){
    // console.log("app vis:",visible_vars_temp)
    this.visible_vars=visible_vars_temp;
  }
}

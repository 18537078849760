import { EventEmitter } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { ApiService } from '../api.service';
var CountryListService = /** @class */ (function () {
    function CountryListService(apiService) {
        this.apiService = apiService;
        this.country_list = null;
        // private messageSource= new BehaviorSubject(this.country_list);
        // currentMessage=this.messageSource.asObservable();
        this.onGetData = new EventEmitter();
        console.log("const country");
        // this.setCountryList();
    }
    CountryListService.prototype.setCountryList = function () {
        var _this = this;
        // if (this.country_list == null) {
        this.apiService.getCountryListData().subscribe(function (result) {
            console.log("api country");
            _this.country_list = result["values"];
            console.log("after country:", _this.country_list);
            // this.messageSource.next(this.country_list)
            _this.onGetData.emit(_this.country_list);
        });
        // }
    };
    CountryListService.prototype.getCountryList = function () {
        return this.country_list;
    };
    return CountryListService;
}());
export { CountryListService };

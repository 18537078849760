<section class="section banner" id="mainContent">
                
  <a href="javascript:void(0);">
      <div class="bannerwrap">
          <img class="bannerimg" src="../../assets/images/banner33.jpg"  alt="Banner Image"/>
      </div>
  </a>
</section>


<div class="tncmaincontainer">
  <div class="tncwrapper">
      <div class="headerwrap">
        <h4 class="tncheader">Terms & Conditions</h4>
      </div>
      <div class="tncpoints">
          <div class="pointshead">1. Membership</div>
          <div class="pointscontent">
              <p>BOUNZ program is owned & operated by City Points Loyalty Card Services LLC in UAE (Dubai License
                  no. 810252).</p>
              <p>BOUNZ is free to join. All citizens, residents, and tourists in the UAE are eligible and just
                  need to download
                  and register on the BOUNZ mobile app, available at Apple App Store and Google Play Store. Should
                  any of the
                  following events occur, we reserve the right to terminate membership and revoke any accrued
                  BOUNZ:</p>
              
              <ul class="tnclist">
                  <li>Failure to comply with BOUNZ’s terms and conditions.</li>
                  <li>Supplying false or misleading information to us or our partners.</li>
                  <li>Abuse of any membership privileges.</li>
                  <li>If members do not use, collect, or spend BOUNZ for a period of fifteen months.</li>
                  <li>Death or bankruptcy of the Primary Cardholder.</li>
              </ul>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">2. Collecting BOUNZ</div>
          <div class="pointscontent">
              <p>Use your BOUNZ app at all partner outlets (online or in-store as applicable) before paying
                   for your purchase. Issuance of BOUNZ is subject to partners’ respective terms and 
                   conditions. Some of our partners offer customers the option of collecting BOUNZ 
                   just by visiting the website or store. You will find them listed under the ‘Visit’ and ‘Click’ section 
                   of the app.  Partners have the right to disable this offering from time to time. For more 
                   details, please refer to FAQs.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">3. Personal Information</div>
          <div class="pointscontent">
              <p>
                  For details regarding your data and privacy, please read our full privacy policy.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">4. General</div>
          <div class="pointscontent">
              <p>
                  We reserve the right at any time and without prior notice to change these terms and conditions, 
                  the qualifying goods and services, the reward portfolio and our partners & suspend or terminate 
                  the BOUNZ Program.
              </p>

              <p>Members can access their BOUNZ account through the app to keep track of activity. BOUNZ has no 
                  monetary value and cannot be sold, transferred, or otherwise dealt with except in accordance with these terms and 
                  conditions. We cannot be held liable for any loss, theft, damage, or unauthorized use of a BOUNZ membership account. </p>

              <p>All exclusions and limitations of liability in these terms and conditions apply for our security and the benefit of 
                  all partners and reward providers. Partners and reward providers do not have any authority,
                   expressed or implied, to make any representation or warranty on our behalf.</p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">5. Transfer of BOUNZ</div>
          <div class="pointscontent">
              <p>BOUNZ collected is personal to your account and cannot typically be transferred. However, the registered 
                  account holder can merge their account with another BOUNZ account by submitting a request to us via 
                  email (to insert email address for support team). Accounts can also be added to a "Household" where there 
                  will be one permitted head who can link family members 
                  after the request has been processed. This allows pooling of BOUNZ into one account.</p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">6. BOUNZ Expiry</div>
          <div class="pointscontent">
              <p>
                  <strong>Two-Year Expiry Policy</strong> <br>
                  BOUNZ earned has a two-year (24 months) expiry policy. For example, all BOUNZ earned up to 30th January 2022 
                  will be subject to expiry on 29th January 2024.
              </p>

              <p>
                  <strong>Fifteen-month Inactive Account Expiry Policy</strong> <br>
                  We may close BOUNZ accounts when there has been no BOUNZ collected or redeemed for a continuous period of 15 months. Upon account 
                  closure, any BOUNZ balance in the account will expire.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">7. Rewards Terms and Conditions</div>
          <div class="pointscontent">
              <p>
                  BOUNZ maintains a catalogue of e-vouchers on the BOUNZ app that specifies the partners where these e-vouchers are redeemable using BOUNZ. These can be availed by primary, secondary cardholders, and authorized person(s) with a registered account. 

              </p>

              <p>
                  BOUNZ can be redeemed in-store with our specified partners. To do so, members must present their membership ID and answer account security questions, if any. Members need to ensure their personal and contact details are up to date on their BOUNZ account to clear security validation. Redemption can be done through the BOUNZ app only. 
              </p>

              <p>
                  Reward options may be withdrawn or amended without prior notice. Prices of rewards may be amended at any time. Rewards are subject to availability, suppliers’ warranties, and restrictions at time of redemption. For redemptions made with a combination of BOUNZ + cash, the monetary amount must be paid via a valid credit card at the time of redemption. Please refer to the relevant reward to see your options.

              </p>

              <p>
                  BOUNZ will be deducted automatically from the member’s account at the time of redemption. Upon redemption, the member will directly enter a sale / purchase relationship with the concerned supplier. City Points Loyalty Card Services LLC, the holding company of the BOUNZ Program shall only act as an agent for redemption of BOUNZ. Members agree that BOUNZ can share their name, account number, address / email address and contact number to the relevant reward supplier where required for them to fulfill the redemption.
              </p>

              <p>
                  City Points Loyalty Card Services LLC reserves the right to pursue any and all available legal and equitable remedies and/or action against members in respect of any misuse of or any fraudulent activity on the account, e-vouchers and/or any rewards or benefits provided to them.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">8. In-Store Rewards</div>
          <div class="pointscontent">
              <p>
                  To redeem BOUNZ in-store, member must present the BOUNZ app or ID number and ensure that the mobile number is the same as registered in the account. A  'One time Password' will be generated by member within the app for verification. BOUNZ can be used for part of the transaction value and any balance must be paid at the time of purchase. BOUNZ collected is not redeemable in conjunction with any other offer, promotion, or discounted rate, unless otherwise stated.
              </p>

              <p>
                  Once the redemption has been processed it cannot be cancelled or refunded. Reward provider standard terms and conditions also apply. Please refer to the relevant in-store rewards on the app to learn more.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">9. e-Gift Vouchers</div>
          <div class="pointscontent">
              <p>
                  Each e-voucher represents the cash value shown. e-vouchers are issued by BOUNZ for use at the specified reward provider and will be sent via email only. BOUNZ or the reward provider are not responsible if e-vouchers are misplaced or used without permission. The e-voucher’s validity depends on the partner brand. Please refer to the partner’s T&C before you proceed to transact. 
              </p>

              <p>
                  e-vouchers can be used towards purchases at participating reward provider outlets to the value mentioned. Multiple e-vouchers can be used per transaction. e-vouchers with a monetary value can be used as part payment. If the price of the purchase exceeds this value, the balance must be paid in cash or card upon. If the price of the purchase is less than the value of the e-vouchers, no change will be given.

              </p>

              <p>
                  e-vouchers cannot be used in conjunction with any other offer or promotion unless mentioned otherwise. They may not be applicable for certain goods or services. Please check with the reward provider for any exclusions.
              </p>

              <p>
                  Printed e-vouchers will not be accepted. e-vouchers cannot be exchanged for cash nor sold or transferred for value. They are non- cancellable and non- refundable and cannot be exchanged for BOUNZ, cash or other rewards.
              </p>

              <p>
                  Vouchers remain the property of BOUNZ. The goods or services purchased using e-vouchers are the responsibility of the rewards provider, not BOUNZ. The reward provider’s standard terms and conditions also apply. The e-voucher is not valid for online purchases unless specified. 
              </p>

              <p>
                  Reward e-vouchers can be used to obtain goods or services to the same value and from the authorized BOUNZ provider detailed on this voucher. e-voucher may not be available on concessions within certain reward providers participating retail outlets. BOUNZ reserves the right to act, in its sole discretion as it should see fit, should an attempt be made to use the voucher on more than one occasion or should it be misused. 
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">10. General Booking Policy</div>
          <div class="pointscontent">
             <p>
              The BOUNZ Travel Shop rewards include flight tickets and hotel stays. These travel rewards be redeemed through the BOUNZ app. Bookings can be paid fully with BOUNZ or with a combination of BOUNZ and money. Any monetary amount is payable with a valid credit card only.
             </p>

             <p>
              Booking prices are quoted based on the best available price across suppliers at the time of booking, for chosen route/destination, class of travel, passenger type, dates, and times of travel.  Travel reward prices therefore vary based on these travel requirements, how far in advance travel is booked, and availability.
             </p>

             <p>
              Bookings are subject to availability and applicable airline and hotel booking policies. In the event of any conflict between the supplier’s booking policies and BOUNZ Travel Shop Terms and Conditions and/or BOUNZ Booking Policy, then the BOUNZ Travel Shop Terms and Conditions and /or Booking Policy shall apply.
             </p>

             <p>
              BOUNZ Travel Shop reserves the right to change the pricing and minimum spend required for travel rewards at any time without prior notice. Travel rewards can be booked for members themselves or for anyone else they choose, provided the traveler/s have the relevant documentation and identification to utilize the booking. Once a booking is made with a registered name, the traveler’s name cannot be changed.
             </p>

             <p>
              Age classification for flight, hotel and car rental bookings is determined by the age of the traveler at the end of the travel period. Dates for all travel bookings must be confirmed at the time of booking. Travel bookings cannot be held. BOUNZ has no liability and will make no refund in the event of any delay, cancellation, overbooking, strike, force majeure, or other causes beyond suppliers' direct control.
             </p>

             <p>
              It is the responsibility of the BOUNZ member to review airline baggage allowances, any travel restrictions, warnings, announcements, and advisories, prior to making travel arrangements to international destinations. It is the responsibility of the BOUNZ member to obtain any required visas for entry to the country of destination or connecting destinations by contacting the respective embassy directly. Visa requirements will not be communicated by BOUNZ.
             </p>

             <p>
              By offering BOUNZ Travel Shop to destinations, BOUNZ does not represent or warrant any travel as advisable or without risk, and as such are not liable for damages or losses that may result from travel to such destinations. Travel insurance is not included in any travel reward redemptions. It is the member’s responsibility to check for eligibility of insurance coverage with their respective insurance companies and/or credit card companies. BOUNZ cannot be held responsible if a claim needs to be made.
             </p>

             <p>
              Refunds can only be credited to the original payment card and are not available for partially used flight booking tickets, hotel stays or car rentals. Refunds will be processed within 14 days of the applicable cancellation fees being paid. For bookings made using BOUNZ that have subsequently expired, the expired BOUNZ will be forfeited and not credited back in the event of a cancellation.
             </p>

             <p>
              Failure to travel or cancel travel bookings within the required timeframe will result in the travel booking being forfeited. Forfeited travel bookings are no longer valid and are non-refundable.
             </p>

             <p>
              The airlines, hotels and car rental companies and other reward suppliers providing travel or other services are independent contractors and not agents or employees of BOUNZ. BOUNZ accepts no responsibility for any loss from or related to travel cancellation or delay, loss or damage to luggage or other property, personal accident or sickness suffered while travelling, refund charges or any other circumstances arising from spending of BOUNZ outside the control of BOUNZ.
             </p>

             <p>
              BOUNZ is not liable for the acts, errors, omissions, representations, warranties, breaches, or negligence of any suppliers.
             </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">11. Flight Rewards</div>
          <div class="pointscontent">
              <p>
                  Flight reward prices apply for the whole flight ticket and include the airfare, airport taxes (where such taxes are payable at the point of payment), duties and charges (including government and other taxes). They do not include the airport taxes that are payable upon departure or arrival directly at certain airports.
              </p>

              <p> 
                  Flight bookings are subject to airline seat availability and applicable fare rules. 
              </p>

              <p>
                  Tickets cannot be redeemed for unaccompanied minors. Additional tickets which need to be paid fully with money cannot be processed by BOUNZ and must be booked directly with a travel agency or airline. Tickets can only be redeemed for passengers under the age of 18 if a ticket for an accompanying adult over the age of 18 is being redeemed at the same time. Child and/or infant tickets cannot be booked separately and/or be added on to an existing booking at a later stage.
              </p>

              <p>
                  Flight bookings are non-transferable. The maximum number of tickets that can be redeemed per booking is nine (9). However, please note that some airlines may have their own restrictions. 
              </p>

              <p>
                  Flight reward bookings cannot be booked for open-ended tickets. Dates for all flight bookings must be confirmed when making the flight reward booking. BOUNZ cannot hold a flight reward booking on a member’s behalf. Flight reward tickets must be ticketed at the time of booking.
              </p>

              <p>
                  In some cases, flight bookings are eligible to collect airline frequent flyer Program points. It is the passenger’s responsibility to contact the airline directly to enquire about eligibility and ensure frequent flyer points are collected from eligible and participating airlines.
              </p>

              <p>
                  Flight bookings, date changes, airport changes, re-routing and ticket upgrades are permitted based on airline terms & conditions. The applicable airline fees and BOUNZ fees are payable by a Visa or MasterCard credit card only.
              </p>

              <p>
                  Each passenger must have a valid passport as part of the flight reward booking process. When making a flight reward booking, the name(s) provided at the time of booking must be the full name of the person(s) travelling, as it appears on their passport. Proof of identity will be required at check-in. It is the member’s responsibility to check for updates regarding any changes in flight timings. BOUNZ and its travel reward supplier cannot be held responsible for communicating any updates related to flight reward bookings.
              </p>

              <p>
                  Electronic tickets will be issued and sent to the members registered email address and to the alternate email address specified in the booking (if any).
              </p>

              <p>
                  BOUNZ is not responsible for the fulfillment of special requests (meal choices, seat allocation, special assistance). The member must  contact the airline directly for all special requests.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">12. Hotel Rewards</div>
          <div class="pointscontent">
              <p>
                  Hotel booking prices apply for the room stay only unless specified otherwise at the time of booking. All additional charges are the liability of the primary/lead guest and payable to the hotel directly. Hotel bookings are subject to the terms and conditions imposed by the hotel.
              </p>

              <p>
                  Hotel bookings must be booked in the name of the primary/lead guest who should be at least 18 years of age at the time of check-in. Bookings are non-transferable. Only one (1) hotel booking can be made per redemption. The maximum number of rooms that can be booked per redemption is three (3) rooms. The maximum number of occupants that can be booked per room is subject to room restrictions as defined by a specific hotel.
              </p>

              <p>
                  Hotel booking confirmation and voucher will be issued and sent to the member's registered email address and to the alternate email address specified in the booking (if any).
              </p>

              <p>
                  A valid photo identification will be required at the time of check-in for each occupant of a hotel room booking. A deposit payment may be required by the hotel at the time of check-in. The amount will be determined by the hotel depending on the applicable room rate and the duration of the stay. The deposit payment can be made via credit card or in cash.
              </p>

              <p>
                  Expenses incurred during the hotel stay such as telephone charges, pay television, meals (unless specified), laundry, room service, mini bar, tips etc. are additional costs which must be paid directly to the hotel at the time of check-out.
              </p>

              <p>
                  Check-in and check-out times are as per the hotel terms and conditions. BOUNZ cannot hold a hotel reward booking on a member’s behalf. Hotel rewards vouchers will be issued at the time of booking.
              </p>
              
              <p>
                  In some cases, hotel bookings are eligible to collect hotel loyalty Program points. It is the member’s responsibility to contact the hotel directly to enquire about eligibility and ensure loyalty points are collected from eligible and participating hotels.
              </p>

              <p>
                  After the hotel booking has been confirmed, changes or cancellations can only be made if permitted within the hotel rules of the booking and charges will apply, payable by a Visa or MasterCard credit card only.
              </p>

              <p>
                  For some hotel suppliers, an amendment request may require a cancellation of the original booking and submitting an entirely new online booking by the member. In these circumstances, rates and room availability will be subject to hotel inventory rules applicable for the period. Best efforts will be made to secure same rates, but this cannot be guaranteed.
              </p>

              <p>
                  Special requests (bed type, honeymoon/anniversary, room location, early check-in, late check-out etc.) should be requested with the hotel directly and are subject to availability at the time of check in and at the hotel’s discretion. BOUNZ and/or the hotel cannot confirm these concessions at the time of booking.
              </p>
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">13. Travel Booking Amendments and Cancellations (Flights & Hotel)</div>
          <div class="pointscontent">
              <p>
                  Fare and tariff rules should be checked at the time of booking. Please refer to the full terms and conditions of the provider on the website. Amendments and cancellations can only be made if permitted within the terms and conditions of the travel booking from the airline, hotel or car rental provider. 
              </p>

              <p>
                  Amendments and cancellations must be made by calling the BOUNZ Travel Rewards Center, between 9am and 6pm Xx time, Monday to Saturday, and at least 1 working day prior to date of flight departure or hotel check-in.
              </p>

              <p>
                  If amendments or cancellations are made directly with the airline, hotel provider or car rental provider, BOUNZ Travel Shop will not be able to subsequently change, cancel or refund your booking.
              </p>

              <p>
                  An amendment fee of AED 150 applies to each amendment to any hotel/car rental booking or per passenger for flight bookings. This fee is in addition to any fees applied by the applicable flight, hotel, or car rental provider. 
              </p>

              <p>
                  A cancellation fee of AED 150 applies to each cancellation of any hotel booking or per passenger for flight bookings. This fee is in addition to any fees applied by the applicable flight or hotel provider.
              </p>

              <p>
                  Refunds can only be credited to the original payment card used. Refunds will be processed within 14 days of the applicable cancellation fees being paid.
              </p>

              <p>
                  Any amendment or cancellation fees imposed by the airline or hotel provider must be paid by a Visa or MasterCard credit card only. Flight bookings, date changes, airport changes, re-routing and ticket upgrades are permitted. The applicable airline fees and BOUNZ fees are payable by a Visa or MasterCard credit card only. In instances where amendments result in a higher price, the customer must pay the additional cost as money with a Visa or MasterCard credit card only. In instances where amendments result in a lower price, a refund may be provided subject to airline conditions and it accepting to process same.
              </p>

              <p>
                  For bookings made using BOUNZ that have subsequently expired, the expired Rewards will be forfeited and not credited back in the event of a cancellation.
              </p>

              <p>
                  Failure to travel or cancel a travel reward booking within the required timeframe will result in the travel booking being forfeited. Forfeited travel bookings are no longer valid and are non-refundable.
              </p>

              <p>
                  Refunds are not available for partially used flight booking tickets or hotel stays.
              </p>

              <p>
                  BOUNZ accepts no responsibility for any loss from or related to travel cancellation or delay, loss or damage to luggage or other property, personal accident or sickness suffered while travelling, refund charges or any other circumstances arising from spending of BOUNZ outside the control of BOUNZ. The airlines and hotels and other reward suppliers providing travel or other services are independent contractors and not agents or employees of BOUNZ.
              </p>

              <p>
                  BOUNZ has no liability and will not make a refund in the event of any delay, cancellation, overbooking, strike, force majeure.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">14. BOUNZ + Cash for Travel Bookings</div>
          <div class="pointscontent">
              <p>
                  You will earn BOUNZ on the cash portion of your BOUNZ + Cash used to make all travel bookings.
              </p>

              <p>
                  Rewards related to card payments elements of your bookings will be credited to your account 60 days after you have completed your travel. For flight bookings, both outbound and inbound (if any) parts of your trip will be credited as one transaction into your BOUNZ account. For hotel bookings, you will receive the applicable BOUNZ 60 days following your check-out date.
              </p>

              <p>
                  Changes on BOUNZ + Cash bookings are permitted based on airline/hotel terms & conditions. The applicable airline/hotel fees and BOUNZ fees are payable by a Visa or MasterCard credit card only. Rewards earned on card payments elements will be computed on the final card payment made but will not include the BOUNZ amendment fee component of the payment.
              </p>

              <p>
                  For BOUNZ + Cash bookings, any fare, room rate differences or additional taxes or surcharges can only be paid using a valid credit card. Rewards earned on card payments elements will be computed on the final card payment made but will not include the BOUNZ amendment fee component of the payment.
              </p>

              <p>
                  BOUNZ related to any card payments will only be awarded if you have completed your travel and/or hotel stay. They will not be awarded for cancelled, no-show or voided bookings.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">15. Your Privacy</div>
          <div class="pointscontent">
              <p>
                  As the operator of the BOUNZ Program, we recognize your trust is fundamental to the value of the Program and are committed to protecting your privacy. Protecting the privacy and confidentiality of your personal information has always been a fundamental principle in our relationship with you, our partners, and reward providers.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">16. How is your data and information used?</div>
          <div class="pointscontent">
              <p>
                  By participating in the BOUNZ Program, you consent that we will collect, process, and use information about you and any additional card holders within your account. This information includes your registration details, information about the use of your BOUNZ Card, shopping purchases, and other information that you give us (together "your information").
              </p>

              <p>
                  This information will allow us to administer the BOUNZ Program - including the management of member accounts, to accurately record and update BOUNZ balances including spending of BOUNZ. Periodically you may be asked to provide additional personal information via market research or surveys (‘member information’). Your ‘member information’ is processed and stored in secure and confidential databases. If you choose not to provide this information, it will not affect your ability to collect or spend BOUNZ.
              </p>

              <p>
                  Your information may be analyzed to see how you use the BOUNZ Program, to understand your interests and to send you (and/or additional members on your account) information and offers for the products or services which are most likely to add value to you. When you join the program, you automatically agree to receive these communications to make the most of what BOUNZ has to offer.
              </p>

              <p>
                  We protect your information; our business depends on it. We will only share your information within the BOUNZ group, i.e., our partners participating in the BOUNZ Program and their group companies and companies contracted to process and manage transactions such as BOUNZ spend requests and other communication. The list of companies participating in the BOUNZ program is on the BOUNZ app. 
              </p>

              <p>
                  For further information, you can get in touch with us via the app or contact the BOUNZ call-center. 
              </p>

              <p>
                  We may record calls to monitor the quality of our services for training, audit, and security purposes.
              </p>
              
          </div>
      </div>
      <div class="tncpoints">
          <div class="pointshead">17. Opt Out.</div>
          <div class="pointscontent">
              <p>
                  If members do not wish to receive marketing or promotional communications in electronic, printed, or verbal format, they can opt out in the BOUNZ app. Their ability to collect or spend BOUNZ will not be affected. Once opted out, members will not be notified of any special offers or promotions.
              </p>

              <p>
                  This opt out is not applicable to communications relating to the management of the BOUNZ account, changes to the program, or the holding companies.
              </p>
              
          </div>
      </div>
  </div>
</div>

<div class="backbtn"><a routerLink="/home" style="color: white;">🡰</a></div>

<button (click)="topFunction()" id="myBtn" title="Go to top" style="background-color: black;"><img src="../../../assets/images/backtotop-arrow.png"
alt="back to top arrow" /></button>